import React, {Component} from 'react'
import ReportPDF from "./reports/CyberShadows Cybersecurity Newsletter - October 2024.pdf"
import { ReactComponent as ISubmit } from './icons/history_log/send.svg';
import { ReactComponent as IShadowHUD } from './icons/menu/ShadowHUD.svg';
import { ReactComponent as IETIDetections } from './icons/menu/ETIDetections.svg';
import { ReactComponent as IThreatLandscape } from './icons/menu/ThreatLandscape.svg';
import { ReactComponent as IETIManagement } from './icons/menu/ETIManagement.svg';
import { ReactComponent as IThreatHunts } from './icons/menu/ThreatHunts.svg';
import { ReactComponent as IATTCKMapping } from './icons/menu/ATT&CKMapping.svg';
import { ReactComponent as IRiskPanel } from './icons/menu/RiskPanel.svg';
import { ReactComponent as ISupplyChainRisk } from './icons/menu/SupplyChainRisk.svg';
import { ReactComponent as ICredentialTheft } from './icons/menu/CredentialTheft.svg';
import { ReactComponent as IInsiderThreat } from './icons/menu/InsiderThreat.svg';
import { ReactComponent as ISessionHijacking } from './icons/menu/SessionHijacking.svg';
import { ReactComponent as IPhishingShield } from './icons/menu/PhishingShield.svg';
import { ReactComponent as IBrandProtection } from './icons/menu/BrandProtection.svg';
import { ReactComponent as IVIPProtection } from './icons/menu/VIPProtection.svg';
import { ReactComponent as IDomainTakedown } from './icons/menu/DomainTakedown.svg';
import { ReactComponent as ICompliance } from './icons/menu/Compliance.svg';
import { ReactComponent as IMail } from './icons/menu/Mail.svg';
import { ReactComponent as IPostIRCheck } from './icons/menu/PostIRCheck.svg';
import { ReactComponent as IDNSTunneling } from './icons/menu/DNSTunneling.svg';
import { ReactComponent as IPickpocket } from './icons/menu/Pickpocket.svg';
import { ReactComponent as IGeoZoning } from './icons/menu/GeoZoning.svg';
import { ReactComponent as IShadowFire } from './icons/menu/KryptoFire(NDR).svg';
import { ReactComponent as ILogArchiving } from './icons/menu/LogArchiving.svg';
import { ReactComponent as IEPPEDR } from './icons/menu/EPPEDR.svg';
import { ReactComponent as IK2IHUD } from './icons/menu/K2I HUD.svg';
import { ReactComponent as I3rdparty } from './icons/client_playbook/3rdparty-i.svg';
import { ReactComponent as IEscalation } from './icons/client_playbook/escalation-i.svg';
import { ReactComponent as IArrowUp } from './icons/fda/arrow-up-i.svg';
import { ReactComponent as IArrowDown } from './icons/fda/arrow-down-i.svg';
import { ReactComponent as IArrowCenter } from './icons/fda/arrow-center-i.svg';
import { ReactComponent as IBoxChecked } from'./icons/fda/box-checked-i.svg';
import { ReactComponent as IBoxUnchecked } from'./icons/fda/box-unchecked-i.svg';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import Select, { StylesConfig } from "react-select"
import { ColourOption } from './types.ts';
import 'react-circular-progressbar/dist/styles.css';
import Dashboard from './pages/dashboard.component'
import ETIManagement from './pages/etimanagement.component'
import Dropdown from 'react-bootstrap/Dropdown';
import Detections from "./pages/detections.component";
import RiskPanel from "./pages/riskpanel.component";
import SupplyChainRisk from "./pages/supplychainrisk.component";
import ThreatLandscape from "./pages/threatlandscape.component";
import CredentialTheft from "./pages/credentialtheft.component";
import ThreatHunts from "./pages/threathunts.component";
import InsiderThreat from "./pages/insiderthreat.component";
import SessionHijacking from "./pages/sessionhijacking.component";
import PhishingShield from "./pages/phishingshield.component";
import BrandProtection from "./pages/brandprotection.component";
import VIPProtection from "./pages/vipprotection.component";
import DomainTakedown from "./pages/domaintakedown.component";
import PostIRCheck from "./pages/postircheck.component";
import ShadowGRC from "./pages/shadowgrc.component";
import ShadowMail from "./pages/shadowmail.component";
import ShadowFireComponent from "./pages/shadowfire.component";
import EPPEDRMGT from "./pages/eppedrmgt.component";
import DNSTunneling from "./pages/dnstunneling.component";
import PickPocket from "./pages/pickpocket.component";
import GeoZoning from "./pages/geozoning.component";
import MitreAttack from "./pages/mitre.component";
import K2IHud from "./pages/k2ihud.component";
import ReactModal from 'react-modal';
import MDEditor from "@uiw/react-md-editor";
import Collapsible from "react-collapsible";
import {byFips as country_lookup_fips, byIso as country_lookup, countries} from "country-code-lookup";
import {CircleFlag} from "react-circle-flags";
import { Document, Page } from 'react-pdf';

function round(num, decimalPlaces) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
}

function get_time_string_ms(time) {
    if (time) {
        let time_val = time/1000
        return new Date(time_val).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' ').replace('Z', '') + time_val.toString().split(".")[1];
    }
    return ""
}

function get_time_string(time) {
    if (time) {
        let date = new Date(time * 1000);
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function extract_domain(text) {
    let result = '';
    const pattern = /.{3,}\.[a-zA-Z0-9_]{2,}(?:\.[a-zA-Z0-9_]{2,})?/;
    const protocolPrefix = /^([a-zA-Z]+):\/\//;
    const match = text.match(pattern);
    if (match) {
        result = match[0];
        const protocolMatch = result.match(protocolPrefix);
        if (protocolMatch) {
            result = result.replace(protocolMatch[0], '');
        }
        if (result.startsWith('www.')) {
            return result.slice(4);
        }
    }
    return result;
}


function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

let countryOptions = [];

const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "#18181B",
        borderColor: "#18181B",
        color: "white"
    }),
    menuList: (styles) => ({
        ...styles,
        backgroundColor: "#18181B",
        borderColor: "#18181B",
        color: "white",
        "::-webkit-scrollbar": {
            width: "4px",
        },
        "::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#A1A1AA",
            borderRadius: "16px"
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                    ? "#27272A"
                    : isFocused
                        ? "#27272A"
                        : undefined,
            color: "white",

            ':active': {
                ...styles[':active'],
                backgroundColor: "#27272A",
            },
        };
    },
    input: (styles) => ({ ...styles, color: "white" }),
    placeholder: (styles) => ({ ...styles, color: "white" }),
    singleValue: (styles) => ({ ...styles, color: "white" }),
};

function getMenuIcon(name, off) {
    switch (name) {
        case "ShadowHUD":
            return (
                <IShadowHUD style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ETI Detections":
            return (
                <IETIDetections style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Threat Landscape":
            return (
                <IThreatLandscape style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ETI Management":
            return (
                <IETIManagement style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Threat Hunts":
            return (
                <IThreatHunts style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ATT&CK Mapping":
            return (
                <IATTCKMapping style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Risk Panel":
            return (
                <IRiskPanel style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Supply Chain Risk":
            return (
                <ISupplyChainRisk style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Credential Theft":
            return (
                <ICredentialTheft style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Insider Threat":
            return (
                <IInsiderThreat style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Session Hijacking":
            return (
                <ISessionHijacking style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Phishing Shield":
            return (
                <IPhishingShield style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Brand Protection":
            return (
                <IBrandProtection style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "VIP Protection":
            return (
                <IVIPProtection style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Domain Takedown":
            return (
                <IDomainTakedown style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Post IR Check":
            return (
                <IPostIRCheck style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "DNS Tunneling":
            return (
                <IDNSTunneling style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Pickpocket":
            return (
                <IPickpocket style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Geo Zoning":
            return (
                <IGeoZoning style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ShadowFire (NDR)":
            return (
                <IShadowFire style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "Log Archiving":
            return (
                <ILogArchiving style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ShadowGRC":
            return (
                <ICompliance style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "ShadowMail":
            return (
                <IMail style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "EPP/EDR Mgt":
            return (
                <IEPPEDR style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        case "K2i HUD":
            return (
                <IK2IHUD style={{
                    width: "20px",
                    height: "20px",
                    color: off ? "#71717A" : "#F9B233",
                }}/>
            )
        default:
            return ""
    }
}

function get_playbook_data (priority) {
    switch (priority) {
        case "P1":
            return {
                title: "P1: High Impact Threat Remediation Playbook",
                objective: "Immediate identification, containment, and eradication of P1-level threats, which pose a severe risk to the organisation.",
                note: "Always involve legal and regulatory bodies according to your industry's standards. If data exfiltration is confirmed, additional legal and regulatory reporting may be required.",
                steps: [
                    {
                        title: "Initial Identification",
                        text: "- Alert Priority: P1\n" +
                            "- Responsible Team: SOC, InfoSec\n" +
                            "- Immediate Action: Confirm the IoA, escalate to team lead, and inform stakeholders\n"
                    },
                    {
                        title: "Containment",
                        text: "- Short-Term: Isolate affected systems from the network\n" +
                            "- Long-Term: Plan network segmentation to limit lateral movement\n" +
                            "- Responsible Team: SOC, Network Operations\n"
                    },
                    {
                        title: "Data Preservation",
                        text: "- Action: Snapshot affected systems, capture network traffic, and secure logs\n" +
                            "- Responsible Team: SOC, Forensic Team\n"
                    },
                    {
                        title: "Data Exfiltration Investigation",
                        text: "- Action: Review network logs, data access records, and unusual data transfers to determine if data has been exfiltrated\n" +
                            "- Responsible Team: SOC, InfoSec, Forensic Team\n"
                    },
                    {
                        title: "Eradication",
                        text: "- Action: Remove malicious files, kill unauthorized processes, and patch vulnerabilities\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Recovery",
                        text: "- Action: Restore and validate system functionality for business operations\n" +
                            "- Responsible Team: SOC, IT Operations\n"
                    },
                    {
                        title: "Communication",
                        text: "- Action: Update stakeholders and document the incident\n" +
                            "- Responsible Team: SOC, InfoSec, PR Team (if applicable)\n"
                    },
                    {
                        title: "Post-Incident Analysis",
                        text: "- Action: Conduct a root cause analysis, lessons learned, and update incident response policies\n" +
                            "- Responsible Team: SOC, InfoSec, Management\n"
                    },
                ]
            }
        case "P2":
            return {
                title: "P2: Medium-High Impact Threat Remediation Playbook",
                objective: "Swift identification, containment, and removal of P2-level threats, which pose a medium-to-high risk to the organisation.",
                note: "Always involve legal and regulatory bodies according to your industry's standards. If data exfiltration is confirmed, additional legal and regulatory reporting may be required.",
                steps: [
                    {
                        title: "Initial Identification",
                        text: "- Alert Priority: P2\n" +
                            "- Responsible Team: SOC, InfoSec\n" +
                            "- Immediate Action: Confirm the IoA, notify SOC team, and alert relevant department leads\n"
                    },
                    {
                        title: "Containment",
                        text: "- Short-Term: Limit permissions of affected accounts and partially isolate affected systems\n" +
                            "- Long-Term: Assess for additional vulnerabilities that may be exploited in the future\n" +
                            "- Responsible Team: SOC, Network Operations\n"
                    },
                    {
                        title: "Data Preservation",
                        text: "- Action: Log affected system activities, store relevant network traffic, and backup event logs\n" +
                            "- Responsible Team: SOC, Forensic Team\n"
                    },
                    {
                        title: "Data Exfiltration Investigation",
                        text: "- Action: Examine network logs and data access records for signs of unauthorized data export\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Credential Review and Reset",
                        text: "- Action: Review and reset all passwords; revoke all session cookies and tokens, especially when info-stealer malware is detected\n" +
                            "- Responsible Team: SOC, IT Administration\n"
                    },
                    {
                        title: "Eradication",
                        text: "- Action: Delete malicious files, block malicious IPs, and update security measures\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Recovery",
                        text: "- Action: Validate affected systems and accounts to restore normal operations\n" +
                            "- Responsible Team: SOC, IT Operations\n"
                    },
                    {
                        title: "Communication",
                        text: "- Action: Brief stakeholders and complete incident documentation\n" +
                            "- Responsible Team: SOC, InfoSec, Department Leads\n"
                    },
                    {
                        title: "Post-Incident Analysis",
                        text: "- Action: Perform a less urgent root cause analysis and policy update based on lessons learned\n" +
                            "- Responsible Team: SOC, InfoSec, Management\n"
                    },
                ]
            }
        case "P3":
            return {
                title: "P3: Medium Impact Threat Remediation Playbook for Limited-Scope Malware",
                objective: "Effective identification, containment, and removal of P3-level threats, which are typically novel and limited to specific environments, posing a medium risk to the organisation.",
                note: "Refer to your organisation's guidelines to determine if reporting to legal and regulatory bodies is necessary for P3-level threats.",
                steps: [
                    {
                        title: "Initial Identification",
                        text: "- Alert Priority: P3\n" +
                            "- Responsible Team: SOC, InfoSec\n" +
                            "- Immediate Action: Confirm the IoA, notify the SOC team, and alert relevant stakeholders.\n"
                    },
                    {
                        title: "Containment",
                        text: "- Short-Term: Quarantine affected systems or devices to prevent malware spread.\n" +
                            "- Long-Term: Identify and secure the specific environments vulnerable to this novel malware.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Data Preservation",
                        text: "- Action: Backup logs and any other essential data related to the compromised environment.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Data Exfiltration Investigation",
                        text: "- Action: Check logs and storage for signs of unauthorized data transfer.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Environment and Credentials Review",
                        text: "- Action: Evaluate the environments for threats and reset credentials associated with compromised environments.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Eradication",
                        text: "- Action: Delete or neutralise malicious components and apply necessary security patches.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Recovery",
                        text: "- Action: Validate the compromised environment and systems, ensuring the malware has been successfully eradicated.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Communication",
                        text: "- Action: Update stakeholders and document the incident.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Post-Incident Analysis",
                        text: "- Action: Conduct an after-action review to update security measures and learn from the incident.\n" +
                            "- Responsible Team: SOC, InfoSec, Management\n"
                    }
                ]
            }
        case "P4":
            return {
                title: "P4: Low Impact Threat Remediation Playbook",
                objective: "To efficiently identify, contain, and remove P4-level threats, which generally have a low impact on the organisation but should not be ignored.",
                note: "P4-level threats generally do not require legal or regulatory involvement. However, always check your organisation’s specific guidelines to confirm.",
                steps: [
                    {
                        title: "Initial Identification",
                        text: "- Alert Priority: P4\n" +
                            "- Responsible Team: SOC, InfoSec\n" +
                            "- Immediate Action: Confirm the IoA, notify the SOC team for tracking, no need for immediate stakeholder notification.\n"
                    },
                    {
                        title: "Basic Containment",
                        text: "- Short-Term: Apply least-privilege rules to affected systems, no complete isolation required.\n" +
                            "- Long-Term: Monitor for signs of escalation.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Data Preservation",
                        text: "- Action: Backup relevant logs but extensive snapshots are generally not required.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Data Exfiltration Investigation",
                        text: "- Action: Perform a quick scan of logs to rule out data export, focus on network and application logs.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Credentials Review",
                        text: "- Action: Check for signs of unauthorized access but no mass reset required unless explicitly compromised.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Eradication",
                        text: "- Action: Remove or block offending software, URLs, or IP addresses.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Recovery",
                        text: "- Action: Confirm that the malware has been eradicated and reinstate any modified settings to their proper configuration.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Communication",
                        text: "- Action: Log the incident for internal records. Stakeholder communication usually not required.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Post-Incident Analysis",
                        text: "- Action: Perform a quick review to identify any required updates to security measures to prevent recurrence.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                ]
            }
        case "S2":
            return {
                title: "Supply Chain Risk Management Playbook for Ransomware Leak Site Incidents",
                objective: "This playbook is designed to guide your organisation through the response to incidents involving suppliers that have been compromised and are listed on ransomware leak sites, which poses a high risk of intellectual property theft and GDPR breaches.",
                note: "This playbook provides a structured approach to managing the high risk associated with compromised suppliers listed on ransomware leak sites. Regular updates to this playbook are essential to adapt to the evolving cyber threat landscape.",
                steps: [
                    {
                        title: "Incident Response Team Activation",
                        text: "- Assemble the Team: Immediately gather members from your IT, security, legal, compliance, and supply chain teams.\n" +
                            "- Assign Responsibilities: Clearly define roles for incident handling, communications, and decision-making.\n"
                    },
                    {
                        title: "Initial Alert and Assessment",
                        text: "- Review the Alert: Understand the specifics from the initial alert about the compromised supplier and the potential for data publication.\n" +
                            "- Initial Impact Analysis: Determine potential GDPR implications and the risk of intellectual property (IP) theft and connected networks.\n"
                    },
                    {
                        title: "Communication Plan",
                        text: "- Internal Notification: Brief your organisation's key stakeholders on the incident, keeping GDPR implications in mind.\n" +
                            "- External Communication Strategy: Plan for potential communications to partners, affected customers, and regulatory bodies.\n"
                    },
                    {
                        title: "Investigate and Contain",
                        text: "- Identify the Connection: Trace the host that attempted the connection and the user responsible for the action.\n" +
                            "- Containment Measures: Isolate any systems that might be compromised to prevent further data exfiltration or malware spread.\n"
                    },
                    {
                        title: "Risk Evaluation and Decision Making",
                        text: "- Supplier Evaluation: Assess the supplier's role in your supply chain and the sensitivity of the data they hold.\n" +
                            "- Legal Implications: Consult with the legal team to understand the implications under GDPR and other relevant laws.\n" +
                            "- Decision Points: Decide on the necessity of the supplier and whether to suspend or terminate the relationship temporarily.\n"
                    },
                    {
                        title: "Mitigation Strategies",
                        text: "- Action Plan: Implement measures to protect against IP theft and personal data breaches.\n" +
                            "- Enhanced Monitoring: Intensify scrutiny of all network traffic to and from the supplier.\n" +
                            "- Data Protection Measures: Review and enhance data protection measures, including encryption and access controls.\n"
                    },
                    {
                        title: "Documentation and Regulatory Reporting",
                        text: "- Record Keeping: Document all findings, actions, and communications.\n" +
                            "- Regulatory Reporting: Prepare for any necessary breach notifications under GDPR.\n"
                    },
                    {
                        title: "Post-Incident Review and Improvement",
                        text: "- After-Action Review: Evaluate the effectiveness of the incident response and identify lessons learned.\n" +
                            "- Update Policies: Revise policies and procedures to strengthen defences against similar incidents.\n"
                    },
                    {
                        title: "Ongoing Vigilance",
                        text: "- Continuous Monitoring: Maintain heightened monitoring for signs of breach or data publication.\n" +
                            "- Regular Training: Conduct regular security awareness training, focusing on ransomware and supplier-related risks.\n"
                    },
                ]
            }
        case "S3":
            return {
                title: "Supply Chain Risk Management Playbook Recently Reported Attacks",
                objective: "This playbook provides guidelines for managing and responding to supply chain risks. It is designed to help you swiftly and effectively address incidents where your network interacts with potentially compromised external entities.",
                note: "This playbook is a dynamic document that should be regularly reviewed and updated to reflect evolving threats and best practices in supply chain risk management.",
                steps: [
                    {
                        title: "Incident Response Team Activation",
                        text: "- Identify the Team: Assemble a cross-functional team comprising members from IT, security, legal, and supply chain management.\n" +
                            "- Designate Roles: Assign specific roles and responsibilities, including a team leader, communications coordinator, and technical experts.\n"
                    },
                    {
                        title: "Initial Assessment",
                        text: "- Gather Information: Review the alert details, including the affected entity, the nature of the breach, and the host name that attempted the connection.\n" +
                            "- Evaluate Impact: Assess the potential impact on your operations, considering the nature of your relationship with the compromised entity.\n"
                    },
                    {
                        title: "Communication",
                        text: "- Internal Communication: Inform relevant internal stakeholders of the incident and the steps being taken.\n" +
                            "- External Communication: If necessary, prepare to communicate with external parties such as suppliers, customers, or regulatory bodies.\n"
                    },
                    {
                        title: "Investigate the Connection",
                        text: "- User Identification: Identify the user or system associated with the host that attempted the connection.\n" +
                            "- Interview and Understand: Speak with the identified user to understand the context of the connection. Determine if it relates to a supplier or technology integral to your operations.\n"
                    },
                    {
                        title: "Risk Evaluation and Decision Making",
                        text: "- Analyse the Connection: Determine the nature and necessity of the connection to the breached entity.\n" +
                            "- Risk Assessment: Assess the level of risk posed by this connection to your supply chain.\n" +
                            "- Decision Making: Decide whether to maintain, modify, or terminate the relationship with the affected entity.\n"
                    },
                    {
                        title: "Mitigation Strategies",
                        text: "- Containment: If the risk is high, isolate the affected systems to prevent further exposure.\n" +
                            "- Security Measures: Update security protocols, change passwords, and review access controls.\n" +
                            "- Supply Chain Adjustments: If necessary, adjust your supply chain operations to reduce dependency on the compromised entity.\n"
                    },
                    {
                        title: "Documentation and Reporting",
                        text: "- Incident Log: Document all actions taken, including interviews, decisions made, and changes implemented.\n" +
                            "- Compliance Reporting: Ensure all actions comply with relevant regulations and industry standards.\n"
                    },
                    {
                        title: "Review and Improvement",
                        text: "- After-Action Review: Conduct a review to evaluate the response effectiveness and identify areas for improvement.\n" +
                            "- Policy Updates: Update policies and procedures based on lessons learned from the incident.\n"
                    },
                    {
                        title: "Continuous Monitoring",
                        text: "- Ongoing Vigilance: Continue to monitor for potential threats and vulnerabilities within the supply chain.\n" +
                            "- Regular Updates: Regularly update your risk management strategies and response plans.\n"
                    },
                ]
            }
        case "C1":
            return {
                title: "Compromised Credentials Response Playbook",
                objective: "This playbook outlines the steps to be taken when credentials within your organisation are suspected of being compromised, particularly through malware activities.",
                note: "This playbook serves as a guide to manage the compromise of credentials due to malware effectively. Regular reviews and updates are essential to maintain its effectiveness in light of evolving cyber threats.",
                steps: [
                    {
                        title: "Incident Response Team Activation",
                        text: "- Assemble the Response Team: Gather your cyber security, IT, and relevant departmental heads.\n" +
                            "- Clarify Roles: Ensure each team member understands their role in the response process.\n"
                    },
                    {
                        title: "Initial Assessment and Containment",
                        text: "- Confirm the Breach: Verify the compromised credentials report and understand the scope of the issue.\n" +
                            "- Isolate Affected Systems: Immediately disconnect affected systems from the network to prevent further malware spread.\n"
                    },
                    {
                        title: "Endpoint Security Analysis",
                        text: "- Malware Scanning: Initiate a thorough scan for malware across all impacted devices.\n" +
                            "- Identify Infection Vectors: Determine how the malware entered your network to prevent future breaches.\n"
                    },
                    {
                        title: "Credential Management",
                        text: "- Revoke Sessions: Immediately terminate all active sessions associated with the compromised credentials.\n" +
                            "- Password Resets: After ensuring endpoints are clean, reset passwords for the compromised accounts.\n" +
                            "- Multi-Factor Authentication: Strengthen security by enforcing MFA on all accounts, especially those with access to sensitive data.\n"
                    },
                    {
                        title: "User Notification and Guidance",
                        text: "- Inform Affected Users: Notify users of compromised credentials, advising them to be vigilant, especially against phishing attempts.\n" +
                            "- Security Best Practices: Reiterate the importance of not reusing passwords and secure password creation.\n"
                    },
                    {
                        title: "Network and Access Review",
                        text: "- Audit Network Access: Review logs to determine if unauthorised access occurred.\n" +
                            "- Review User Rights: Ensure that users have the minimum necessary privileges for their roles.\n"
                    },
                    {
                        title: "Legal and Compliance Considerations",
                        text: "- Regulatory Reporting: Consult with the legal team to determine if the breach requires reporting under relevant laws, such as GDPR.\n" +
                            "- Document Actions: Keep a detailed record of all steps taken in response to the incident for regulatory and compliance purposes.\n"
                    },
                    {
                        title: "Ongoing Monitoring",
                        text: "- Enhanced Surveillance: Implement monitoring for signs of persistence or lateral movement by the attacker.\n"
                    },
                    {
                        title: "Post-Incident Analysis and Improvement",
                        text: "- Debrief and Root Cause Analysis: Conduct a meeting to review the incident and understand the root cause.\n" +
                            "- Revise Security Policies: Update incident response and security policies based on findings.\n"
                    },
                    {
                        title: "Education and Training",
                        text: "- Awareness Programs: Roll out or reinforce cyber security awareness programs focusing on the importance of secure credential management and recognising malware.\n"
                    },
                ]
            }
        case "B1":
            return {
                title: "B1: Low Impact Threat Remediation Playbook",
                objective: "To efficiently identify, contain, and remove B1-level threats, which generally have a low impact on the organisation but should not be ignored.",
                note: "B1-level threats generally do not require legal or regulatory involvement. However, always check your organisation’s specific guidelines to confirm.",
                steps: [
                    {
                        title: "Initial Identification",
                        text: "- Alert Priority: B1\n" +
                            "- Responsible Team: SOC, InfoSec\n" +
                            "- Immediate Action: Confirm the IoA, notify the SOC team for tracking, no need for immediate stakeholder notification.\n"
                    },
                    {
                        title: "Basic Containment",
                        text: "- Short-Term: Apply least-privilege rules to affected systems, no complete isolation required.\n" +
                            "- Long-Term: Monitor for signs of escalation.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Data Preservation",
                        text: "- Action: Backup relevant logs but extensive snapshots are generally not required.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Data Exfiltration Investigation",
                        text: "- Action: Perform a quick scan of logs to rule out data export, focus on network and application logs.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Credentials Review",
                        text: "- Action: Check for signs of unauthorized access but no mass reset required unless explicitly compromised.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Eradication",
                        text: "- Action: Remove or block offending software, URLs, or IP addresses.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                    {
                        title: "Recovery",
                        text: "- Action: Confirm that the malware has been eradicated and reinstate any modified settings to their proper configuration.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Communication",
                        text: "- Action: Log the incident for internal records. Stakeholder communication usually not required.\n" +
                            "- Responsible Team: SOC\n"
                    },
                    {
                        title: "Post-Incident Analysis",
                        text: "- Action: Perform a quick review to identify any required updates to security measures to prevent recurrence.\n" +
                            "- Responsible Team: SOC, InfoSec\n"
                    },
                ]
            }
    }
    return {}
}

class page_states {
    static pages = {}
}

function page_state(name, state) {
    if (name && state) {
        page_states.pages[name] = state
    }
    if (name in page_states.pages) {
        return page_states.pages[name]
    }
    return undefined
}

class page {
    static data = {}
}
function page_data(val) {
    if (val) {
        page.data = val
    }
    return page.data
}

function tooltip_info(id) {
    switch (id) {
        case "P1":
            return "Characterised by high-impact capabilities often linked to nation-state actors or the later stage of a ransomware attack. P1 threats require immediate action."
        case "P2":
            return "Typically criminal in nature and are aimed at harvesting user credentials/tokens or providing remote access to the target environment. P2 threats should be treated as high priority."
        case "P3":
            return "Generally novel forms of malware with a limited scope of attack. These often target specific platforms like Android phones. P3 threats should be treated as moderate priority."
        case "P4":
            return "Low-impact malware types such as crypto miners, website scanners, or reconnaissance-related malware. P4 threats pose minimal immediate risk but should not be ignored."
        case "Packet Analysis":
            return "Operational status of CyberShadow Deployed Agents. Green indicates active, Red signifies offline."
        case "ETI Threat Hunting":
            return "Proactive searches for identified Indicators of Attack within the client's internal network."
        case "Endpoint Statistics":
            return "Counts of active CyberShadow devices and license capacity, with a 7-day real-time uptime visualisation."
        case "Geo-Zone Threat Map":
            return "Graphical representation of Command and Control alerts from P1 to P4."
        case "Criminal & Nation State Trending Analytics":
            return "Real-time analysis highlighting trends in malware activity, with a focus on growth and decline."
        case "C2 IoA Search":
            return "Search functionality for locating potential Indicators of Attack via IP address tracking."
        case "Top 5 Countries Hosting Malware":
            return "Ranking of countries by the activity level of Command and Control server hosting."
        case "Activity Log":
            return "Records of threat alerts pertinent to the client."
        case "False Positive Rate":
            return "Metrics indicating the proportion of alerts falsely identified as threats."
        case "ShadowBreach":
            return "Count of newly identified compromised credentials affecting the client as detected by ShadowBreach."
        case "Supply Chain Risk":
            return "Tally of newly identified compromised companies and number supply chain elements impacting the client."
        case "Wi-Fi Risk":
            return "Live count of secure Wi-Fi connections within the network compared to insecure."
        case "Session Hijacking":
            return "Live monitoring for session takeover activities and live count of at risk sessions."
        case "Insider Risk":
            return "Live monitoring and  detections of potential malicious insider activities."
        case "DNS Tunneling":
            return "Live monitoring Live detection of malicious DNS activity."
        case "PickPocket":
            return "Real-time cyber deception environment deployment and monitoring."
        case "Domain Takedown":
            return "Service for domain takedown operations, available via credit purchase."
        case "Risk Log":
            return "Compilation of threat alerts relevant to the client."
        case "IoAs By Priority":
            return "Chart indicating the percentage distribution of P1 - P4 IoAs."
        case "Brand Protection":
            return "Cyber brand protection shields a company's reputation and intellectual property, prevents revenue loss from cyber threats, and reinforces regulatory compliance, enhancing trust and market credibility."
        case "Zero-Day Detections":
            return "A visual alerting mechanism for all P1 - P4 detections. P1 threats require immediate action. P2 threats should be treated as high priority. P3 threats should be treated as moderate priority. P4 threats pose minimal immediate risk but should not be ignored."
        case "Log Archiving & Retention":
            return "Indicators displaying the size and health of all configured Log types showing respective retention policies."
        case "Insider Threat":
            return "Live monitoring and  detections of potential malicious insider activities."
        case "ETI License Usage":
            return "Number of licenses utilized"
        case "Endpoint Status":
            return "Current status of all endpoints"
        case "Ransomware Breaches":
            return "Number of reported ransomware victims in the last 30 Days"
        case "Active Groups (Count)":
            return "Number of active ransomware groups over the last 30 Days compared to the number of active groups over the last 90 Days"
        case "Victimology Analysis":
            return "Top 5 ransomware groups over the last 30 Days"
        case "New Ransomware Groups":
            return "New ransomware groups detected in the last 30 Days"
        case "Patch Status":
            return "Overview of the company patch status"
        case "Attacks By Country":
            return "Ransomware activity by country over the last 30 Days"
        case "Victims Indexed":
            return "Victim database statistics"
        case "ILogin":
            return "Username / Email address"
        case "IPass":
            return "Password"
        case "ICookie":
            return "Session cookie"
        default:
            return ""
    }
}

export default class MainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cp_contacts_open: [true, true, true],
            sign_out: false,
            username: '',
            email: '',
            company: '',
            keys: {},
            stats: {},
            nav_text: "ShadowHUD",
            sel_offset: 0,
            cp_contact_idx: 0,
            render_id: 0,
            options: [],
            side_collapsed: 0,
            showRequestIntel: false,
            showReporting: false,
            showPDFReport: false,
            showClientPlaybook: false,
            showSupplySentry: false,
            showBDV: false,
            showSDW: false,
            showNetflow: false,
            showHUDAdmin: false,
            detection_data: "",
            rfi_description: "",
            rfi_to_investigate: "",
            rfi_priority: "Select Urgency",
            rfi_category: "Threat Category",
            rfi_format: ".PDF",
            rfi_formats: [".PDF", ".DOCX", ".XLS"],
            rfi_categories: ["Malware", "Phishing", "APT", "OSINT", "Other"],
            report_type: "CTI",
            report_types: ["CTI", "Risk", "ASM"],
            report_period: "Day",
            report_periods: ["Day", "Week", "Month", "Year", "All"],
            entryOpen: [true, false, false],
            cp_data: {},
            nf_collapsibles_state: {},
            nf_data: {},
            nf_detection_data: {},
            nf_selected: {},
            ss_data: [],
            bdv_data_org: [],
            bdv_search: "",
            bdv_data: [],
            shadow_wall_data_org: [],
            shadow_wall_data: [],
            shadow_wall_search: "",
            HUD_Admin_data: [],
            ss_delete_confirm: [],
            hud_delete_confirm: [],
            ss_entry: {}

        };
        this.page_change = this.page_change.bind(this);
        this.sign_out = this.sign_out.bind(this);
        this.company_change = this.company_change.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
        this.getPage = this.getPage.bind(this);
        this.collapse_btn = this.collapse_btn.bind(this);
        this.open_threat_card = this.open_threat_card.bind(this);
        this.make_trigger_div = this.make_trigger_div.bind(this);
        this.open_mitre = this.open_mitre.bind(this);
        this.reset_rfi = this.reset_rfi.bind(this);
        this.reset_report = this.reset_report.bind(this);
        this.get_parent_title = this.get_parent_title.bind(this);
        this.open_client_playbook = this.open_client_playbook.bind(this);
        this.open_netflow = this.open_netflow.bind(this);
        this.close_client_playbook = this.close_client_playbook.bind(this);
        this.close_netflow = this.close_netflow.bind(this);
        this.submit_rfi = this.submit_rfi.bind(this);
        this.safe_index = this.safe_index.bind(this);
        this.open_detection = this.open_detection.bind(this);
        this.get_chip_color = this.get_chip_color.bind(this);
        this.get_cp_contact_render = this.get_cp_contact_render.bind(this);
        this.get_cp_contacts = this.get_cp_contacts.bind(this);
        this.open_supply_sentry = this.open_supply_sentry.bind(this);
        this.open_bdv = this.open_bdv.bind(this);
        this.close_bdv = this.close_bdv.bind(this);
        this.bdv_filter = this.bdv_filter.bind(this);
        this.sdw_filter = this.sdw_filter.bind(this);
        this.close_supply_sentry = this.close_supply_sentry.bind(this);
        this.close_client_playbook = this.close_client_playbook.bind(this);
        this.contact_supply_sentry = this.contact_supply_sentry.bind(this);
        this.contact_hud_admin = this.contact_hud_admin.bind(this);
        this.get_nf_hostname = this.get_nf_hostname.bind(this);
        this.get_nf_ip = this.get_nf_ip.bind(this);
        this.get_nf_detail_ip = this.get_nf_detail_ip.bind(this);
        this.get_nf_detail_hostname = this.get_nf_detail_hostname.bind(this);
        this.get_nf_port_details = this.get_nf_port_details.bind(this);
        this.nf_is_ioa = this.nf_is_ioa.bind(this);
        this.nf_is_before = this.nf_is_before.bind(this);
        this.nf_sort = this.nf_sort.bind(this);
        this.get_pretty_usage = this.get_pretty_usage.bind(this);
        this.ss_active = this.ss_active.bind(this);
        this.close_HUD_Admin = this.close_HUD_Admin.bind(this);
        this.open_HUD_Admin = this.open_HUD_Admin.bind(this);
        this.fetch_client_playbook = this.fetch_client_playbook.bind(this);
        this.open_ai_reporting = this.open_ai_reporting.bind(this);
        this.close_ai_reporting = this.close_ai_reporting.bind(this);
        this.open_shadow_wall = this.open_shadow_wall.bind(this)
        this.close_shadow_wall = this.close_shadow_wall.bind(this)
        this.tick = this.tick.bind(this);
    }

    nf_ref = React.createRef()

    side_bar = [
        {
            "CTI": {
                "ETI Detections": false,
                "Threat Landscape": false,
                "ETI Management": false,
                "ATT&CK Mapping": false,
                "Supply Chain Risk": false,
                "ShadowFire (NDR)": true,
                // "Log Archiving": true,
                "EPP/EDR Mgt": true,
                "ShadowMail": true,
                "ShadowGRC": true
            }
        },
        // {
        //     "ASM": {
        //         "Asset Discovery": true,
        //         "Vulnerability Scanning": true,
        //         "Cyber Risk Mgt": true,
        //         "ASM Management": true,
        //     }
        // },

    ]

    close_ai_reporting() {

    }

    open_ai_reporting() {

    }

    sdw_filter(search) {
        let sdw = []
        if (search === "") {
            sdw = this.state.shadow_wall_data_org
        } else {
            this.state.shadow_wall_data_org.forEach((item) => {
                if (item["ioa"].includes(search) || item["name"].includes(search)
                    || item["group"].includes(search) || item["host"].includes(search)) {
                    sdw.push(item)
                }
            })
        }
        this.setState({shadow_wall_data: sdw})
    }

    bdv_filter(search) {
        let bdv = []
        if (search === "") {
            bdv = this.state.bdv_data_org
        } else {
            this.state.bdv_data_org.forEach((item) => {
                if (item["domain"].includes(search)) {
                    bdv.push(item)
                }
            })
        }
        this.setState({bdv_data: bdv})
    }

    get_pretty_usage(bytes) {
        if (bytes < Math.pow(2, 10)) {
            return bytes.toString() + " B"
        } else if (bytes < Math.pow(2, 20)) {
            return round(bytes / Math.pow(2, 10), 2).toString() + " KB"
        } else if (bytes < Math.pow(2, 30)) {
            return round(bytes / Math.pow(2, 20), 2).toString() + " MB"
        } else if (bytes < Math.pow(2, 40)) {
            return round(bytes / Math.pow(2, 30), 2).toString() + " GB"
        } else {
            return round(bytes / Math.pow(2, 40), 2).toString() + " TB"
        }
    }

    ss_active() {
        let ss_entry = this.state.ss_entry
        return Object.keys(ss_entry).length === 3 && ss_entry["supplier"] !== ""
            && ss_entry["country"] !== "" && ss_entry["domain"] !== ""
    }

    get_cp_contact_render(item, idx) {
        return (
            <div style={{
                width: "100%",
                marginTop: 8,
                flexDirection: 'column',
                gap: 8,
                display: 'flex'
            }}
            >
                <div style={{
                    width: "100%",
                    paddingLeft: 4,
                    alignItems: 'center',
                    gap: 8,
                    display: 'flex'
                }}
                >
                    <div style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: "32px",
                        gap: 10,
                        display: 'flex'
                    }}>
                        <div style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: 'Inter',
                            fontWeight: '600',
                            wordWrap: 'break-word'
                        }}>#{idx + 1}
                        </div>
                    </div>
                    <div style={{
                        minHeight: 40,
                        paddingRight: 4,
                        width: "280px",
                        gap: 8,
                        display: 'flex'
                    }}
                    >
                        <div style={{
                            flexDirection: 'column',
                            display: 'flex'
                        }}>
                            <div style={{
                                color: 'white',
                                fontSize: 16,
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                wordWrap: 'break-word'
                            }}>
                                {item["name"]}
                            </div>
                            <div style={{
                                color: 'white',
                                fontSize: 12,
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                letterSpacing: 0.06,
                                wordWrap: 'break-word'
                            }}>
                                {item["position"]}
                            </div>
                        </div>
                    </div>
                    <div style={{
                        height: 32,
                        paddingRight: 4,
                        alignItems: 'center',
                        gap: 8,
                        display: 'flex'
                    }}
                    >
                        <div style={{
                            flexDirection: 'column',
                            display: 'inline-flex'
                        }}>
                            <div style={{
                                color: 'white',
                                fontSize: 12,
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                letterSpacing: 0.06,
                                wordWrap: 'break-word'
                            }}>
                                {item["number"]}
                            </div>
                            <div style={{
                                color: 'white',
                                fontSize: 12,
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                letterSpacing: 0.06,
                                wordWrap: 'break-word'
                            }}>
                                {item["email"]}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{alignSelf: 'stretch', height: 1, background: '#52525B'}}/>
            </div>
        )
    }

    get_cp_contacts() {
        let business_hours = []
        let out_hours = []
        let all_hours = []
        if (Object.keys(this.state.cp_data).length === 0) {
            return {
                business_hours: [],
                out_hours: [],
                all_hours: []
            }
        }

        if (this.state.cp_contact_idx === 0) {
            this.state.cp_data["IRContactsOperational"].forEach(entry => {
                let item = {}
                item["name"] = this.safe_index(entry, "Name", "FirstAndLast")
                item["email"] = this.safe_index(entry, "Email")
                item["number"] = this.safe_index(entry, "MobileNumber")
                item["position"] = this.safe_index(entry, "Position")
                business_hours.push(item)
            })
            this.state.cp_data["IRContactsOutOfHours"].forEach(entry => {
                let item = {}
                item["name"] = this.safe_index(entry, "Name", "FirstAndLast")
                item["email"] = this.safe_index(entry, "Email")
                item["number"] = this.safe_index(entry, "MobileNumber")
                item["position"] = this.safe_index(entry, "Position")
                out_hours.push(item)
            })
        } else if (this.state.cp_contact_idx === 1) {
            this.state.cp_data["RiskContact"].forEach(entry => {
                let item = {}
                item["name"] = this.safe_index(entry, "Name", "FirstAndLast")
                item["email"] = this.safe_index(entry, "Email")
                item["number"] = this.safe_index(entry, "MobileNumber")
                item["position"] = this.safe_index(entry, "Position")
                business_hours.push(item)
            })
        } else if (this.state.cp_contact_idx === 2) {
            this.state.cp_data["EscalationPOC"].forEach(entry => {
                let item = {}
                if (this.safe_index(entry, "SDeskOrIndividual")) {
                    item["name"] = this.safe_index(entry, "EscalationSDeskDetails", "ServiceDeskProvider")
                    item["email"] = this.safe_index(entry, "EscalationSDeskDetails", "ServiceDeskEmailAddress")
                    item["number"] = this.safe_index(entry, "EscalationSDeskDetails", "ServiceDeskHotlineNumber")
                    item["position"] = "Service Desk"
                } else {
                    item["name"] = this.safe_index(entry, "EscalationIndividualDetails", "Name", "FirstAndLast")
                    item["email"] = this.safe_index(entry, "EscalationIndividualDetails", "Email")
                    item["number"] = this.safe_index(entry, "EscalationIndividualDetails", "MobileNumber")
                    item["position"] = this.safe_index(entry, "EscalationIndividualDetails", "Position")
                }
                all_hours.push(item)
            })
        } else if (this.state.cp_contact_idx === 3) {
            this.state.cp_data["CriticalThirdPartyContacts"].forEach(entry => {
                let item = {}
                if (this.safe_index(entry, "ThirdPartySDeskOrIndividual")) {
                    item["name"] = this.safe_index(entry, "EscalationSDeskDetails", "SDeskProvider")
                    item["email"] = this.safe_index(entry, "EscalationSDeskDetails", "SDeskEmail")
                    item["number"] = this.safe_index(entry, "EscalationSDeskDetails", "SDeskHotline")
                    item["position"] = "Service Desk"
                } else {
                    item["name"] = this.safe_index(entry, "EscalationIndividualDetails", "Name", "FirstAndLast")
                    item["email"] = this.safe_index(entry, "EscalationIndividualDetails", "Email")
                    item["number"] = this.safe_index(entry, "EscalationIndividualDetails", "MobileNumber")
                    item["position"] = this.safe_index(entry, "EscalationIndividualDetails", "Position")
                }
                business_hours.push(item)
            })
        }


        return {
            business_hours: business_hours,
            out_hours: out_hours,
            all_hours: all_hours
        }
    }

    get_chip_color(level) {
        switch (level) {
            case "Platinum":
                return "#F8FAFC"
            case "Gold":
                return "#FBBF24"
            case "Silver":
                return "#CBD5E1"
            case "Bronze":
                return "#F97316"
        }
        return "#64748B"
    }

    reset_report() {
        this.reset_rfi()
        this.setState({showReporting: false, report_type: "CTI", report_period: "Day"})
    }

    submit_rfi() {
        let jsonData = {
            "key": this.state.keys[this.state.company],
            "user": this.state.username,
            "email": this.state.email,
            "category": this.state.rfi_category,
            "description": this.state.rfi_description,
            "investigate": this.state.rfi_to_investigate,
            "urgency": this.state.rfi_priority,
            "format": this.state.rfi_format
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/submit_rfi", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.success) {
                        this.reset_rfi()
                    } else {
                        console.log("Failed to submit RFI")
                    }
                });
            }
        }).catch((err) => {
            console.log("rfi", err)
        })

    }

    contact_supply_sentry(data, del) {
        let jsonData = {
            "key": this.state.keys[this.state.company],
        }
        if (data) {
            if (del) jsonData["oid"] = data
            else jsonData["entry"] = data
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/supply_sentry", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    this.setState({ss_data: json["sentry"], showSupplySentry: true})
                });
            }
        }).catch((err) => {
            console.log("ss", err)
        })
    }

    contact_hud_admin(oid, del) {
        let jsonData = {
            "key": this.state.keys[this.state.company],
        }
        if (oid) {
            if (del) jsonData["oid"] = oid
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/hud_accounts", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if ("data" in json) {
                        this.setState({HUD_Admin_data: json["data"], showHUDAdmin: true})
                    }
                });
            }
        }).catch((err) => {
            console.log("aa", err)
        })
    }

    open_shadow_wall() {
        let jsonData = {
            "key": this.state.keys[this.state.company],
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/shadow_wall", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    this.setState({shadow_wall_data: json["data"], shadow_wall_data_org: json["data"], showSDW: true})
                });
            }
        }).catch((err) => {
            console.log("SDW", err)
        })
    }

    close_shadow_wall() {
        this.setState({shadow_wall_data: [], shadow_wall_search: "", showSDW: false})
    }

    open_bdv() {
        let jsonData = {
            "key": this.state.keys[this.state.company],
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/bad_domain_vault", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    this.setState({bdv_data: json["data"], bdv_data_org: json["data"], showBDV: true})
                });
            }
        }).catch((err) => {
            console.log("bdv", err)
        })
    }

    close_bdv() {
        this.setState({bdv_data: [], showBDV: false})
    }

    open_supply_sentry() {
        this.contact_supply_sentry()
    }

    close_supply_sentry() {
        this.setState({showSupplySentry: false, ss_data: [], ss_entry: {}, ss_delete_confirm: []})
    }

    fetch_client_playbook(company, key) {
        let jsonData = {
            "key": key !== undefined ? key : this.state.keys[this.state.company],
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + (company !== undefined ? company : this.state.company) + "/client_playbook", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    this.setState({cp_data: json})
                });
            }
        }).catch((err) => {
            console.log("fetch_client_playbook", err)
        })
    }

    open_client_playbook() {
        this.setState({showClientPlaybook: true})
    }

    open_HUD_Admin() {
        this.contact_hud_admin()
    }

    open_netflow(data) {
        this.setState({nf_detection_data: data})
        let jsonData = {
            "key": this.state.keys[this.state.company],
            "host": data["host_key"] ? data["host_key"] : data["key"],
            "ip": data["ioa"] ? data["ioa"] : data["dest_ip"],
            "time_point": data["updated"] * 1000000
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/netflow_overview", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if ("overview" in json) {
                        this.setState({nf_data: json, showNetflow: true})
                    }
                });
            }
        }).catch((err) => {
            console.log("netflow", err)
        })
    }

    close_netflow() {
        this.setState({
            showNetflow: false,
            nf_detection_data: {},
            nf_data: {},
            nf_collapsibles_state: {},
            nf_selected: {}
        })
    }

    close_HUD_Admin() {
        this.setState({showHUDAdmin: false, HUD_Admin_data: [], hud_delete_confirm: []})
    }

    close_client_playbook() {
        this.setState({showClientPlaybook: false})
    }

    reset_rfi() {
        this.setState({
            showRequestIntel: false,
            rfi_priority: "Select Urgency",
            rfi_category: "Threat Category",
            rfi_format: ".PDF",
            rfi_description: "",
            rfi_to_investigate: ""
        })
    }

    open_mitre(filter) {
        if (filter === "") {
            this.setState({mitre_filter: filter})
        } else {
            this.setState({mitre_filter: [{label: filter, value: filter}], nav_text: "ATT&CK Mapping"})
        }
    }

    safe_index(data, key1, key2, key3) {
        if (key3) {
            if (key1 in data && key2 in data[key1] && key3 in data[key1][key2] && data[key1][key2][key3] !== undefined && data[key1][key2][key3] !== null) {
                return data[key1][key2][key3]
            }
        } else if (key2) {
            if (key1 in data && key2 in data[key1] && data[key1][key2] !== undefined && data[key1][key2] !== null) {
                return data[key1][key2]
            }
        } else if (key1 in data && data[key1] !== undefined && data[key1] !== null) {
            return data[key1]
        }
        return "N/A"
    }

    open_detection(data) {
        this.setState({detection_data: data, nav_text: "ETI Detections"})
    }

    open_threat_card(malware) {
        if (malware === "-") return
        if (malware === "") {
            this.setState({threat_card_malware: malware})
        } else {
            this.setState({threat_card_malware: malware, nav_text: "Threat Landscape"})
        }
    }

    tick() {
        let zoom = Math.ceil(((window.innerWidth / 2560) * 100)) + "%"
        if (document.body.style.zoom !== zoom) {
            document.body.style.zoom = zoom
        }
    }

    componentDidMount() {
        countryOptions = []
        countries.forEach(country => {
            countryOptions.push({value: country.iso3, label: country.country})
        });

        let user = this.props.user()
        if (user.username === '') {
            this.setState({sign_out: true})
        } else {
            this.fetch_client_playbook(user.company, user.keys[user.company])
            this.setState({username: user.username, email: user.email, company: user.company, keys: user.keys})
            if (user.company === "KryptoKloud Ltd") {
                this.side_bar[0]["CTI"]["ShadowFire (NDR)"] = false
                this.side_bar[0]["CTI"]["EPP/EDR Mgt"] = false
                this.side_bar[0]["CTI"]["ShadowGRC"] = false
                this.side_bar[0]["CTI"]["ShadowMail"] = false
            }
        }
        this.playbook_interval = setInterval(
            () => {
                if (!this.state.cp_data["URN"]) {
                    this.fetch_client_playbook()
                }
            },
            1000
        );
        this.zoom_update = setInterval(
            () => this.tick(),
            200
        );
    }

    componentWillUnmount() {
        clearInterval(this.playbook_interval)
        clearInterval(this.zoom_update);
    }

    getPage() {
        if (this.state.sign_out) return ""
        switch (this.state.nav_text) {
            case "ShadowHUD":
                return <Dashboard page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}
                                  get_playbook_data={get_playbook_data} open_client_playbook={this.open_client_playbook}
                                  open_netflow={this.open_netflow}
                                  open_threat_card={this.open_threat_card} open_mitre={this.open_mitre}/>
            case "ETI Detections":
                return <Detections page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}
                                   get_playbook_data={get_playbook_data}
                                   open_client_playbook={this.open_client_playbook}
                                   open_netflow={this.open_netflow}
                                   open_detection={this.open_detection}
                                   open_threat_card={this.open_threat_card} open_mitre={this.open_mitre}/>
            case "Risk Panel":
                return <RiskPanel page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}
                                  get_playbook_data={get_playbook_data}/>
            case "Supply Chain Risk":
                return <SupplyChainRisk page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}/>
            case "Credential Theft":
                return <CredentialTheft page_state={page_state} page_data={page_data}/>
            case "Threat Landscape":
                return <ThreatLandscape page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}
                                        open_threat_card={this.open_threat_card} open_mitre={this.open_mitre}/>
            case "ATT&CK Mapping":
                return <MitreAttack page_state={page_state} page_data={page_data} open_mitre={this.open_mitre}/>
            case "Threat Hunts":
                return <ThreatHunts page_state={page_state} page_data={page_data}/>
            case "Insider Threat":
                return <InsiderThreat page_state={page_state} page_data={page_data}/>
            case "Session Hijacking":
                return <SessionHijacking page_state={page_state} page_data={page_data}/>
            case "Phishing Shield":
                return <PhishingShield page_state={page_state} page_data={page_data}/>
            case "Brand Protection":
                return <BrandProtection page_state={page_state} page_data={page_data}/>
            case "VIP Protection":
                return <VIPProtection page_state={page_state} page_data={page_data}/>
            case "Domain Takedown":
                return <DomainTakedown page_state={page_state} page_data={page_data}/>
            case "Post IR Check":
                return <PostIRCheck page_state={page_state} page_data={page_data}/>
            case "ShadowFire (NDR)":
                return <ShadowFireComponent page_state={page_state} page_data={page_data}/>
            case "EPP/EDR Mgt":
                return <EPPEDRMGT page_state={page_state} page_data={page_data}/>
            case "ShadowGRC":
                return <ShadowGRC page_state={page_state} page_data={page_data}/>
            case "ShadowMail":
                return <ShadowMail page_state={page_state} page_data={page_data}/>
            case "ETI Management":
                return <ETIManagement page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}/>
            case "DNS Tunneling":
                return <DNSTunneling page_state={page_state} page_data={page_data}/>
            case "Pickpocket":
                return <PickPocket page_state={page_state} page_data={page_data}/>
            case "Geo Zoning":
                return <GeoZoning page_state={page_state} page_data={page_data}/>
            case "K2i HUD":
                return <K2IHud page_state={page_state} page_data={page_data} tooltip_info={tooltip_info}
                               get_playbook_data={get_playbook_data} open_threat_card={this.open_threat_card}/>
            default:
                return ""
        }
    }

    nf_sort(overview) {
        return Object.keys(overview).sort(function (a, b) {
            return overview[b]["first_packet"] - overview[a]["first_packet"];
        });
    }

    get_nf_port_details(port_data) {
        let data = []
        Object.keys(port_data).forEach((proto, i) => {
            Object.keys(port_data[proto]).forEach((port, i) => {
                data.push({
                    proto: proto, port: port, out: port_data[proto][port]["out"], time: port_data[proto][port]["time"],
                    p_out: port_data[proto][port]["p_out"], d_out: port_data[proto][port]["d_out"],
                    p_in: port_data[proto][port]["p_in"], d_in: port_data[proto][port]["d_in"]
                })
            })
        })
        return data
    }

    get_nf_detail_ip(key, entry) {
        if ("host_names" in entry && Array.from(entry["host_names"]).length !== 0) {
            return key
        }
        return ""
    }

    get_nf_detail_hostname(key, entry) {
        if ("host_names" in entry && Array.from(entry["host_names"]).length !== 0) {
            return entry["host_names"][0]
        }
        return key
    }

    get_nf_ip() {
        if ("host_names" in this.state.nf_data && Array.from(this.state.nf_data["host_names"]).length !== 0) {
            return this.state.nf_detection_data["ioa"] ? this.state.nf_detection_data["ioa"] : this.state.nf_detection_data["dest_ip"]
        }
        return ""
    }

    nf_is_ioa(key) {
        let ioa = this.state.nf_detection_data["ioa"] ? this.state.nf_detection_data["ioa"] : this.state.nf_detection_data["dest_ip"]
        if (!(ioa in this.state.nf_selected)) {
            let nf_selected = this.state.nf_selected
            nf_selected[ioa] = true
            this.setState({nf_selected: nf_selected})
        }
        return key === ioa
    }

    nf_is_before(key) {
        let ioa = this.state.nf_detection_data["ioa"] ? this.state.nf_detection_data["ioa"] : this.state.nf_detection_data["dest_ip"]
        return this.state.nf_data["overview"][key]["first_packet"] < this.state.nf_data["overview"][ioa]["first_packet"]
    }

    get_nf_hostname() {
        if ("host_names" in this.state.nf_data && Array.from(this.state.nf_data["host_names"]).length !== 0) {
            return this.state.nf_data["host_names"][0]
        }
        return this.state.nf_detection_data["ioa"] ? this.state.nf_detection_data["ioa"] : this.state.nf_detection_data["dest_ip"]
    }

    renderSwitch() {
        let page = this.getPage()
        if (page !== "") {
            page_data(this.state)
        }
        return page
    }

    company_change(e) {
        this.setState({company: e.currentTarget.id, cp_data: {}})
        this.fetch_client_playbook(e.currentTarget.id, this.state.keys[e.currentTarget.id])
        page_data(this.state)
        page_states.pages = {}
    }

    collapse_btn(e) {
        if (this.state.side_collapsed === 1 || this.state.side_collapsed === 0) {
            this.setState({side_collapsed: 2})
        } else {
            this.setState({side_collapsed: 1})
        }
    }

    page_change(e) {
        //console.log("page change!")
        this.setState({nav_text: e.currentTarget.id})
    }

    getCompanies() {
        return Object.keys(this.state.keys).map((company) =>
            <Dropdown.Item className="Dropdown-item" onClick={this.company_change}
                           id={company}>{company}</Dropdown.Item>
        )
    }

    get_parent_title(sub) {
        let title = ""
        this.side_bar.forEach((entry) => {
            Object.keys(entry).forEach((key) => {
                if (sub in entry[key]) {
                    title = key
                }
            })
        })
        if (sub === "ShadowHUD") return "CTI"
        return title
    }

    sign_out() {
        this.setState({sign_out: true})
        this.props.user(true)
    }


    make_trigger_div(title, open) {
        let color = "#71717A"
        if (this.get_parent_title(this.state.nav_text) === title) {
            color = "#a1daf8"
        }
        return (
            <div
                className="menu-entry"
                style={{
                    alignItems: "center",
                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                    transition: 'width 1s ease',
                    justifyContent: "center",

                    zIndex: "0",
                    overflow: "hidden",
                    gap: "2px"
                }}
            >
                <div style={{width: "100%", height: "1px", marginLeft: "12px", background: color}}/>
                <div style={{color: color, fontSize: "12px"}}>{title}</div>
                <div style={{width: "100%", height: "1px", background: color}}/>
                <img src={"/main/menu-arrow-down.svg"} alt={"menu-arrow"} width={20} height={20} style={{
                    marginRight: "4px",
                    transform: open ? 'rotate(180deg)' : '',
                    transition: 'transform 150ms ease',
                }}/>
            </div>
        )
    }

    make_menu_btn(title, off) {
        return (
            <button
                className="menu-entry"
                onClick={off ? () => {
                } : this.page_change}
                id={title}
            >
                <div
                    style={{
                        left: "0px",
                        backgroundColor: this.state.nav_text === title ? "#eab308" : "transparent",
                        width: "2px",
                        height: "40px"
                    }}
                />
                <div
                    style={{
                        backgroundColor: this.state.nav_text === title ? "rgba(234,179,8,0.05)" : "rgba(19, 19, 19, 0.05)",
                        width: this.state.side_collapsed === 2 ? "66px" : "178px",
                        height: "40px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "8px 0px 8px 16px",
                        boxSizing: "border-box",
                        overflow: "hidden",
                        zIndex: "0",
                        transition: 'width 1s ease',
                    }}
                >
                    <div
                        style={{
                            flex: "1",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                        }}
                    >
                        {
                            getMenuIcon(title, off)
                        }
                        <div
                            style={{
                                position: "relative",
                                fontSize: "14px",
                                letterSpacing: "0.01em",


                                color: off ? "#71717A" : "white",
                                textAlign: "left",
                                overflow: "hidden",
                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                transition: 'width 1s ease',
                            }}
                        >
                            <div style={{width: "150px"}}>
                                {title}
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        )
    }

    render() {
        if (this.state.sign_out) {
            return (<Navigate to="/sign-in?page=dashboard" replace={true}/>)
        }
        return (
            <div
                style={{
                    backgroundColor: "#131313",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    fontSize: "12px",
                    color: "#fff",

                }}
                id="main-screen"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPDFReport}
                    contentLabel="Report PDF Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "2200px",
                            height: "1200px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 16
                    }}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}>
                            <div style={{color: "#F9B233"}}>
                                Report PDF
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPDFReport: false})}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>
                        {/*<Document file={"./reports/CyberShadows Cybersecurity Newsletter - October 2024.pdf"}/>*/}
                        <div className={"cp-scroll-bar-div"} style={{
                            width: "100%",
                            height: "95%"
                        }}>
                            <embed
                                src={ReportPDF}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showRequestIntel}
                    contentLabel="RFI Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "740px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}>
                            <div style={{color: "#F9B233"}}>
                                Request for Intelligence (RFI)
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.reset_rfi}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#27272A",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%",
                            height: "90%",
                            gap: "12px",
                        }}
                        >
                            New RFI Request
                            <div style={{display: "flex", gap: "16px"}}>
                                <div style={{color: "#A1A1AA"}}>
                                    Requester:
                                </div>
                                {this.state.username}
                            </div>

                            <div style={{width: "100%"}}>
                                <Dropdown className="RFI-Dropdown-defaults" style={{
                                    zIndex: "200"
                                }}>
                                    <Dropdown.Toggle className="RFI-Dropdown-defaults">
                                        <div style={{width: "100%", textAlign: "left", display: "flex", gap: "8px"}}>
                                            {this.state.rfi_category}
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="RFI-Dropdown-menu">
                                        {this.state.rfi_categories.map((item, i) =>
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_category: item})}>
                                                {item}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            Intelligence Request

                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>

                                <MDEditor
                                    className={"scroll-editor"}
                                    value={this.state.rfi_description}
                                    visibleDragbar={false}
                                    hideToolbar={true}
                                    preview="edit"
                                    extraCommands={[]}
                                    onChange={(data) => {
                                        this.setState({rfi_description: data})
                                    }}
                                    style={{
                                        width: "100%"
                                    }}
                                    height={108}
                                />
                            </div>

                            Indicators of Compromise (IoC) to Investigate

                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>

                                <MDEditor
                                    className={"scroll-editor"}
                                    value={this.state.rfi_to_investigate}
                                    visibleDragbar={false}
                                    hideToolbar={true}
                                    preview="edit"
                                    extraCommands={[]}
                                    onChange={(data) => {
                                        this.setState({rfi_to_investigate: data})
                                    }}
                                    style={{
                                        width: "100%"
                                    }}
                                    height={108}
                                />
                            </div>

                            RFI Urgency
                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>
                                <div style={{width: "100%"}}
                                >
                                    <Dropdown className="RFI-Dropdown-defaults">
                                        <Dropdown.Toggle className="RFI-Dropdown-defaults">
                                            <div
                                                style={{width: "100%", textAlign: "left", display: "flex", gap: "8px"}}>
                                                {

                                                    this.state.rfi_priority.split(' ')[0].toLowerCase() === "select" ?
                                                        ""
                                                        : <img
                                                            src={"/main/rfi-" + this.state.rfi_priority.split(' ')[0].toLowerCase() + "-i.svg"}
                                                            alt="rfi-icon"/>
                                                }
                                                {this.state.rfi_priority}
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="RFI-Dropdown-menu">
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Urgent"})}>
                                                <img src="/main/rfi-urgent-i.svg" alt="rfi-urgent"/>
                                                Urgent
                                            </Dropdown.Item>
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Routine"})}>
                                                <img src="/main/rfi-routine-i.svg" alt="rfi-routine"/>
                                                Routine
                                            </Dropdown.Item>
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Low"})}>
                                                <img src="/main/rfi-low-i.svg" alt="rfi-low"/>
                                                Low
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            Response Format

                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>
                                <div style={{display: "flex", gap: "8px", justifyContent: "center", width: "100%"}}>
                                    {this.state.rfi_formats.map((item, i) =>
                                        <div style={{
                                            display: "flex",
                                            gap: "8px",
                                            marginRight: "auto",
                                            marginLeft: "auto"
                                        }}>
                                            <input type="radio" value={item} checked={this.state.rfi_format === item}
                                                   onChange={(e) => {
                                                       this.setState({rfi_format: e.target.value})
                                                   }}/>
                                            {item}
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>


                        <div style={{marginTop: "8px"}}>
                            <button onClick={this.submit_rfi}
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: "2px",
                                        border: "none",
                                        backgroundColor: "rgba(6,182,212,0.25)",
                                        color: "#06B6D4",
                                        marginRight: "8px",
                                        padding: "4px 24px"
                                    }}
                            >
                                Submit RFI
                            </button>
                        </div>

                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showReporting}
                    contentLabel="Reporting Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "820px",
                            height: "640px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}>
                            <div style={{color: "#F9B233"}}>
                                AI Threat Intelligence Reporting
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.reset_report}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#27272A",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%",
                            height: "90%",
                            gap: "12px",
                        }}
                        >
                            <div style={{display: "flex", gap: "16px"}}>
                                <div style={{color: "#A1A1AA"}}>
                                    Requester:
                                </div>
                                {this.state.username}
                            </div>

                            <div style={{
                                background: "#52525B",
                                alignSelf: "stretch",
                                flexShrink: "0",
                                height: "1px"
                            }}/>

                            Select Report Type

                            <div style={{display: "flex", gap: "8px", width: "100%"}}
                            >
                                {this.state.report_types.map((item, i) =>
                                    <button
                                        style={{
                                            color: this.state.report_type === item ? "#F59E0B" : "#A1A1AA"
                                        }}
                                        className={"reporting-button"} onClick={() => {
                                        this.setState({report_type: item})
                                    }}>
                                        {item}
                                    </button>
                                )}
                            </div>

                            Report Period

                            <div style={{display: "flex", gap: "8px", width: "100%"}}>
                                {this.state.report_periods.map((item, i) =>
                                    <button
                                        style={{
                                            color: this.state.report_period === item ? "#F59E0B" : "#A1A1AA"
                                        }}
                                        className={"reporting-button"} onClick={() => {
                                        this.setState({report_period: item})
                                    }}>
                                        {item}
                                    </button>
                                )}
                            </div>

                            <div style={{
                                background: "#52525B",
                                alignSelf: "stretch",
                                flexShrink: "0",
                                height: "1px"
                            }}/>
                            Report Urgency
                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>
                                <div style={{width: "100%"}}
                                >
                                    <Dropdown className="RFI-Dropdown-defaults">
                                        <Dropdown.Toggle className="RFI-Dropdown-defaults">
                                            <div
                                                style={{width: "100%", textAlign: "left", display: "flex", gap: "8px"}}>
                                                {

                                                    this.state.rfi_priority.split(' ')[0].toLowerCase() === "select" ?
                                                        ""
                                                        : <img
                                                            src={"/main/rfi-" + this.state.rfi_priority.split(' ')[0].toLowerCase() + "-i.svg"}
                                                            alt="rfi-icon"/>
                                                }
                                                {this.state.rfi_priority}
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="RFI-Dropdown-menu">
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Urgent"})}>
                                                <img src="/main/rfi-urgent-i.svg" alt="rfi-urgent"/>
                                                Urgent
                                            </Dropdown.Item>
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Routine"})}>
                                                <img src="/main/rfi-routine-i.svg" alt="rfi-routine"/>
                                                Routine
                                            </Dropdown.Item>
                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                           onClick={() => this.setState({rfi_priority: "Low"})}>
                                                <img src="/main/rfi-low-i.svg" alt="rfi-low"/>
                                                Low
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            Additional Comments
                            <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                            >
                                <div style={{
                                    background: "#71717a",
                                    alignSelf: "stretch",
                                    flexShrink: "0",
                                    width: "1px"
                                }}/>

                                <MDEditor
                                    className={"scroll-editor"}
                                    value={this.state.rfi_description}
                                    visibleDragbar={false}
                                    hideToolbar={true}
                                    preview="edit"
                                    extraCommands={[]}
                                    onChange={(data) => {
                                        this.setState({rfi_description: data})
                                    }}
                                    style={{
                                        width: "100%"
                                    }}
                                    height={108}
                                />
                            </div>

                        </div>


                        <div style={{marginTop: "8px"}}>
                            <button onClick={() => {
                                this.reset_report()
                                this.setState({showPDFReport: true})
                            }}
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: "2px",
                                        border: "none",
                                        backgroundColor: "rgba(6,182,212,0.25)",
                                        color: "#06B6D4",
                                        marginRight: "8px",
                                        padding: "4px 24px"
                                    }}
                            >
                                Generate Report
                            </button>
                        </div>

                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showClientPlaybook}
                    contentLabel="Client Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1200px",
                            height: "800px",
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                {this.state.company} Playbook
                            </div>
                            <div style={{
                                marginLeft: "auto",
                                height: "26px",
                                paddingLeft: 8,
                                paddingRight: 8,
                                background: "rgba(100,116,139,0.25)",
                                borderRadius: 999,
                                border: "2px " + this.get_chip_color(this.safe_index(this.state.cp_data, "ClientAgreementLevel")) + " solid",
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 10,
                                display: 'inline-flex'
                            }}>
                                <div style={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'flex'
                                }}>
                                    <div style={{
                                        textAlign: 'center',
                                        color: this.get_chip_color(this.safe_index(this.state.cp_data, "ClientAgreementLevel")),
                                        fontSize: 14,
                                        fontWeight: '400',
                                        letterSpacing: 0.07,
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.safe_index(this.state.cp_data, "ClientAgreementLevel")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px"
                        }}>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "204px",
                                     padding: "8px 0px 8px 8px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",

                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="company-i"
                                        src="/main/cp-company-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Company Information
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "126px"
                                    }} id="section1">
                                        <div>Company Name:</div>
                                        <div>CNI:</div>
                                        <div>Industry:</div>
                                        <div>Size:</div>
                                        <div>Contract Start:</div>
                                        <div>Contract Renew:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.safe_index(this.state.cp_data, "ClientContactInformation", "CompanyName")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "ClientContactInformation", "CNI") ? "Yes" : "No"}</div>
                                        <div>{this.safe_index(this.state.cp_data, "ClientContactInformation", "Industry")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "ClientContactInformation", "CompanySize")} Employee(s)</div>
                                        <div>{this.safe_index(this.state.cp_data, "AnticipatedStartDate")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "AnticipatedRenewalDate")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "232px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",

                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="service-icon"
                                        src="/main/cp-service-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Service Coverage
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "126px"
                                    }} id="section1">
                                        <div>&nbsp;</div>
                                        <div>Licenses:</div>
                                        <div>&nbsp;</div>
                                        <div>&nbsp;</div>
                                        <div>&nbsp;</div>
                                        <div>24x7x365:</div>
                                        <div>Service Tier:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div style={{
                                            display: "flex"
                                        }}
                                        >
                                            <div style={{width: "100px", fontWeight: "bold"}}>Type</div>
                                            <div style={{width: "100px", fontWeight: "bold"}}>Quantity</div>
                                            <div style={{width: "100px", fontWeight: "bold"}}>Auth to Act</div>
                                            <div style={{width: "100px", fontWeight: "bold"}}>Auto Isolate</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}
                                        >
                                            <div style={{width: "100px"}}>Total</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "HowManyLicenses", "Total")}</div>
                                            <div style={{width: "100px"}}>-</div>
                                            <div style={{width: "100px"}}>-</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}
                                        >
                                            <div style={{width: "100px"}}>Workstation</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "HowManyLicenses", "EndpointLicenses")}</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Endpoints").includes("Authority to Act") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No</div>}
                                            </div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Endpoints").includes("Authority to Isolate") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No
                                                </div>}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}
                                        >
                                            <div style={{width: "100px"}}>Server</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "HowManyLicenses", "ServerLicenses")}</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Servers").includes("Authority to Act") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No</div>}
                                            </div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Servers").includes("Authority to Isolate") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No</div>}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}
                                        >
                                            <div style={{width: "100px"}}>Mobile</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "HowManyLicenses", "MobileLicenses")}</div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Mobiles").includes("Authority to Act") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No</div>}
                                            </div>
                                            <div
                                                style={{width: "100px"}}>{this.safe_index(this.state.cp_data, "IRDeclaration", "Mobiles").includes("Authority to Isolate") ?
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-yes-i.svg"
                                                                                                alt="yes"/>Yes</div> :
                                                <div style={{display: "flex", gap: "4px"}}><img src="/main/cp-no-i.svg"
                                                                                                alt="no"/>No</div>}
                                            </div>
                                        </div>
                                        <div>{this.safe_index(this.state.cp_data, "AdditionalServices").includes("24x7x365 KSOC SIEM Monitoring") ? "Yes" : "No"}</div>
                                        <div>{this.safe_index(this.state.cp_data, "TierChoice")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "150px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",

                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="insurance-icon"
                                        src="/main/cp-insurance-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Cyber Insurance Provider
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "126px"
                                    }} id="section1">
                                        <div>Provider:</div>
                                        <div>Policy Number:</div>
                                        <div>Authority To Act:</div>
                                        <div>IR Hotline:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",

                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "InsuranceCompanyDetails", "CyberInsuranceCompany")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "InsuranceCompanyDetails", "PolicyNumber")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "AuthoritytoAct") ? "Yes" : "No"}</div>
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "InsuranceCompanyDetails", "IRHotline")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "94px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",

                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="insurance-icon"
                                        src="/main/cp-incident-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Incident Response Retainer
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "126px"
                                    }} id="section1">
                                        <div>Provider:</div>
                                        <div>IR Hotline:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "IRRetainer", "RetainerCompanyName")}</div>
                                        <div>{this.safe_index(this.state.cp_data, "CyberInsuranceProviderPOC", "IRRetainer", "ContactNumber")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div style={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                Primary Contacts
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_client_playbook}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: -8,
                            gap: "32px"
                        }}>
                            <button style={{
                                width: '120px',
                                height: '110px',
                                padding: 8,
                                background: '#27272A',
                                borderRadius: 8,
                                border: this.state.cp_contact_idx === 0 ? "1px #F43F5E solid" : "transparent",
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }} onClick={() => this.setState({cp_contact_idx: 0})}
                            >
                                <div style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{display: 'flex', flexDirection: "column", textAlign: 'center'}}>
                                            <span style={{
                                                color: this.state.cp_contact_idx === 0 ? "#F43F5E" : "#A1A1AA",
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>P1 - P4</span>
                                            <span style={{
                                                color: '#A1DAF8',
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>Incident Response Contacts</span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <button style={{
                                width: '120px',
                                height: '110px',
                                padding: 8,
                                background: '#27272A',
                                borderRadius: 8,
                                border: this.state.cp_contact_idx === 1 ? "1px #10B981 solid" : "transparent",
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }} onClick={() => this.setState({cp_contact_idx: 1})}
                            >
                                <div style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{display: 'flex', flexDirection: "column", textAlign: 'center'}}>
                                            <span style={{
                                                color: this.state.cp_contact_idx === 1 ? "#10B981" : "#A1A1AA",
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>RISK</span>
                                            <span style={{
                                                color: '#A1DAF8',
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>Incident Response Contacts</span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <button style={{
                                width: '120px',
                                height: '110px',
                                padding: 8,
                                background: '#27272A',
                                borderRadius: 8,
                                border: this.state.cp_contact_idx === 2 ? "1px #8B5CF6 solid" : "transparent",
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }} onClick={() => this.setState({cp_contact_idx: 2})}
                            >
                                <div style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: "8px",
                                            flexDirection: "column",
                                            textAlign: 'center',
                                            alignItems: "center"
                                        }}>
                                            <IEscalation style={{
                                                width: "32px",
                                                height: "32px",
                                                color: this.state.cp_contact_idx === 2 ? "#8B5CF6" : "#A1A1AA",
                                            }}/>
                                            <span style={{
                                                color: '#A1DAF8',
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>Escalation Contacts</span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <button style={{
                                width: '120px',
                                height: '110px',
                                padding: 8,
                                background: '#27272A',
                                borderRadius: 8,
                                border: this.state.cp_contact_idx === 3 ? "1px #06B6D4 solid" : "transparent",
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }} onClick={() => this.setState({cp_contact_idx: 3})}
                            >
                                <div style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: "8px",
                                            flexDirection: "column",
                                            textAlign: 'center',
                                            alignItems: "center"
                                        }}>
                                            <I3rdparty style={{
                                                width: "32px",
                                                height: "32px",
                                                color: this.state.cp_contact_idx === 3 ? "#06B6D4" : "#A1A1AA",
                                            }}/>
                                            <span style={{
                                                color: '#A1DAF8',
                                                fontFamily: "Inter",
                                                fontSize: 16,
                                                fontWeight: '600',
                                                letterSpacing: 0.03,
                                                wordWrap: 'break-word'
                                            }}>3rd Party Contacts</span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="cp-scroll-bar-div" style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0px 8px 16px 8px",
                            gap: "8px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: "650px"
                        }}
                        >

                            {
                                this.get_cp_contacts()["business_hours"].length > 0 ?
                                    <Collapsible
                                        trigger={(
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 8,
                                                marginTop: 16,
                                                marginBottom: 8
                                            }}>
                                                <img src="/main/cp-hours-1.svg" alt="hours-1" width={24} height={24}/>
                                                <div style={{
                                                    width: '100%',
                                                    color: '#A1DAF8',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    letterSpacing: 0.03,
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Business Hours
                                                </div>
                                                <img src="/main/cp-arrow-up-i.svg" alt="arrow" width={24} height={24}
                                                     style={{
                                                         marginLeft: "auto",
                                                         transform: this.state.cp_contacts_open[0] ? '' : 'rotate(180deg)',
                                                         transition: 'transform 150ms ease',
                                                     }}
                                                />
                                            </div>
                                        )}
                                        open={true}
                                        onOpen={() => {
                                            let val = this.state.cp_contacts_open
                                            val[0] = true;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                        onClose={() => {
                                            let val = this.state.cp_contacts_open;
                                            val[0] = false;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                    >
                                        {
                                            this.get_cp_contacts()["business_hours"].map((item, i) =>
                                                this.get_cp_contact_render(item, i)
                                            )
                                        }
                                    </Collapsible> : ""
                            }

                            {
                                this.get_cp_contacts()["out_hours"].length > 0 ?
                                    <Collapsible
                                        trigger={(
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 8,
                                                marginTop: 16,
                                                marginBottom: 8
                                            }}>
                                                <img src="/main/cp-hours-2.svg" alt="hours-2" width={24} height={24}/>
                                                <div style={{
                                                    width: '100%',
                                                    color: '#A1DAF8',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    letterSpacing: 0.03,
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Out of Hours
                                                </div>
                                                <img src="/main/cp-arrow-up-i.svg" alt="arrow" width={24} height={24}
                                                     style={{
                                                         marginLeft: "auto",
                                                         transform: this.state.cp_contacts_open[1] ? '' : 'rotate(180deg)',
                                                         transition: 'transform 150ms ease',
                                                     }}
                                                />
                                            </div>
                                        )}
                                        open={true}
                                        onOpen={() => {
                                            let val = this.state.cp_contacts_open
                                            val[1] = true;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                        onClose={() => {
                                            let val = this.state.cp_contacts_open;
                                            val[1] = false;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                    >
                                        {
                                            this.get_cp_contacts()["out_hours"].map((item, i) =>
                                                this.get_cp_contact_render(item, i)
                                            )
                                        }
                                    </Collapsible> : ""
                            }


                            {
                                this.get_cp_contacts()["all_hours"].length > 0 ?
                                    <Collapsible
                                        trigger={(
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 8,
                                                marginTop: 16,
                                                marginBottom: 8
                                            }}>
                                                <img src="/main/cp-hours-3.svg" alt="hours-3" width={24} height={24}/>
                                                <div style={{
                                                    width: '100%',
                                                    color: '#A1DAF8',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    letterSpacing: 0.03,
                                                    wordWrap: 'break-word'
                                                }}>
                                                    All Hours (24x7)
                                                </div>
                                                <img src="/main/cp-arrow-up-i.svg" alt="arrow" width={24} height={24}
                                                     style={{
                                                         marginLeft: "auto",
                                                         transform: this.state.cp_contacts_open[2] ? '' : 'rotate(180deg)',
                                                         transition: 'transform 150ms ease',
                                                     }}
                                                />
                                            </div>
                                        )}
                                        open={true}
                                        onOpen={() => {
                                            let val = this.state.cp_contacts_open
                                            val[2] = true;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                        onClose={() => {
                                            let val = this.state.cp_contacts_open;
                                            val[2] = false;
                                            this.setState({cp_contacts_open: val})
                                        }}
                                    >
                                        {
                                            this.get_cp_contacts()["all_hours"].map((item, i) =>
                                                this.get_cp_contact_render(item, i)
                                            )
                                        }
                                    </Collapsible> : ""
                            }
                        </div>

                        <button
                            style={{
                                cursor: "pointer",
                                borderRadius: "2px",
                                border: "1px solid #06B6D4",
                                backgroundColor: "transparent",
                                color: "#06B6D4",
                                position: "relative",
                                marginLeft: "auto"
                            }}
                            id="Contact"
                            onClick={this.open_HUD_Admin}
                        >
                            HUD Admin
                        </button>
                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showNetflow}
                    contentLabel="Netflow"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 1920,
                            height: 1080,
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "65%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                {this.state.nf_detection_data["host"]} - {this.state.nf_detection_data["ip"] ? this.state.nf_detection_data["ip"] : this.state.nf_detection_data["src_ip"]} -
                                Workstation
                            </div>
                        </div>
                        {
                            "overview" in this.state.nf_data ?
                                <div>
                                    <div style={{
                                        width: "100%",
                                        height: "170px",
                                        borderRadius: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#3F3F46",
                                        gap: 16,
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            justifyContent: 'center',
                                            position: "relative",
                                            color: 'white',
                                            fontSize: 22,
                                            fontFamily: 'Inter',
                                            display: "flex",
                                        }}
                                        >
                                            Critical 60x60
                                            <div
                                                style={{
                                                    left: "85%",
                                                    position: "absolute",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                    display: "inline-flex"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        padding: "8px",
                                                        background: "#18181B",
                                                        borderRadius: "2px",
                                                        overflow: "hidden",
                                                        border: "1px #F59E0B solid",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: "4px",
                                                        display: "flex"
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                            color: "white",
                                                            fontSize: "12px",
                                                            fontFamily: "Inter",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word"
                                                        }}
                                                        >
                                                        RAG Status:
                                                    </div>
                                                    <img src="/main/rag-flag-i.svg" alt="flag"/>
                                                    <div
                                                        style={{
                                                            color: "#F59E0B",
                                                            fontSize: "12px",
                                                            fontFamily: "Inter",
                                                            fontWeight: "700",
                                                            wordWrap: "break-word"
                                                        }}>
                                                        AMBER
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="/main/netflow-dotted-long.svg" width={"100%"} alt="dotted-line"/>
                                        <div style={{
                                            width: '100%',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                flex: '1 1 0',
                                                height: 56,
                                                paddingRight: 4,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600'
                                                    }}>{this.state.nf_detection_data["host"]}
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400'
                                                    }}>{this.state.nf_detection_data["ip"] ? this.state.nf_detection_data["ip"] : this.state.nf_detection_data["src_ip"]}
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400'
                                                    }}>Host
                                                    </div>
                                                </div>
                                            </div>

                                            <img width={32} height={32} src="/main/netflow-in-out.svg"
                                                 alt="in-out-arrow"/>

                                            <div style={{
                                                flex: '1 1 0',
                                                height: 56,
                                                paddingRight: 4,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: "pointer",
                                                gap: 8,
                                                display: 'flex',
                                            }}
                                                 onClick={() => {
                                                     this.nf_ref.current?.scrollIntoView({
                                                         behavior: 'smooth',
                                                         block: 'center'
                                                     })
                                                 }}
                                            >
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600'
                                                    }}>{this.get_nf_hostname()}
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400'
                                                    }}>{this.get_nf_ip()}
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400'
                                                    }}>Target
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="scroll-bar-div" style={{
                                        marginTop: -16,
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        padding: 8,
                                        paddingLeft: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                        background: "#27272A",
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                        width: "100%",
                                        height: 840
                                    }}
                                    >
                                        {this.nf_sort(this.state.nf_data["overview"]).map((key, i) =>
                                            <div style={{
                                                display: "flex"
                                            }}>
                                                {
                                                    this.nf_is_ioa(key) ?
                                                        <IArrowCenter
                                                            ref={this.nf_ref}
                                                            style={{
                                                                color: "#F43F5E",
                                                                marginTop: 8,
                                                                height: 56,
                                                                width: 32
                                                            }}/>
                                                        :
                                                            <IArrowUp style={{
                                                                color: key in this.state.nf_selected && this.state.nf_selected[key] ? "#F59E0B" : "#52525B",
                                                                marginTop: 8,
                                                                height: 56,
                                                                width: 32
                                                            }}/>
                                                }

                                                <div style={{
                                                    marginLeft: 4,
                                                }}>
                                                    <div style={{
                                                        gap: 4,
                                                        height: 70,
                                                        display: 'inline-flex',
                                                        alignItems: "center",
                                                        marginLeft: 6,
                                                        width: "100%"
                                                    }}
                                                    >
                                                        {
                                                            this.nf_is_ioa(key) ?
                                                                <IBoxChecked style={{
                                                                    color: "#52525B",
                                                                    height: 22,
                                                                    width: 22
                                                                }}/>
                                                                :
                                                                key in this.state.nf_selected && this.state.nf_selected[key] ?
                                                                    <IBoxChecked style={{
                                                                        cursor: "pointer",
                                                                        color: "white",
                                                                        height: 22,
                                                                        width: 22
                                                                    }}
                                                                                 onClick={() => {
                                                                                     let nf_selected = this.state.nf_selected
                                                                                     nf_selected[key] = false
                                                                                     this.setState({nf_selected: nf_selected})
                                                                                 }}
                                                                    />
                                                                    :
                                                                    <IBoxUnchecked style={{
                                                                        cursor: "pointer",
                                                                        color: "white",
                                                                        height: 22,
                                                                        width: 22
                                                                    }}
                                                                                   onClick={() => {
                                                                                       let nf_selected = this.state.nf_selected
                                                                                       nf_selected[key] = true
                                                                                       this.setState({nf_selected: nf_selected})
                                                                                   }}
                                                                    />
                                                        }

                                                        <div style={{
                                                            flexDirection: 'column',
                                                            display: 'inline-flex',
                                                            marginLeft: 8,
                                                            marginRight: "auto",
                                                            width: 600
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600'
                                                            }}>{this.get_nf_detail_hostname(key, this.state.nf_data["overview"][key])}
                                                            </div>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>{this.get_nf_detail_ip(key, this.state.nf_data["overview"][key])}
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>
                                                                {get_time_string_ms(this.state.nf_data["overview"][key]["first_packet"])}
                                                            </div>
                                                        </div>

                                                        <img width={32} height={32} src="/main/netflow-out.svg"
                                                             alt="net-out"/>

                                                        <div style={{
                                                            flexDirection: 'column',
                                                            display: 'inline-flex',
                                                            marginRight: "auto",
                                                            width: 150
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600'
                                                            }}>{this.get_pretty_usage(this.state.nf_data["overview"][key]["total"]["d_out"])}
                                                            </div>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>Packets: {this.state.nf_data["overview"][key]["total"]["p_out"]}
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>
                                                                Outbound
                                                            </div>
                                                        </div>

                                                        <img width={32} height={32} src="/main/netflow-in.svg"
                                                             alt="net-in"/>

                                                        <div style={{
                                                            flexDirection: 'column',
                                                            display: 'inline-flex',
                                                            marginRight: "auto",
                                                            width: 150
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600'
                                                            }}>{this.get_pretty_usage(this.state.nf_data["overview"][key]["total"]["d_in"])}
                                                            </div>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>Packets: {this.state.nf_data["overview"][key]["total"]["p_in"]}
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400'
                                                            }}>
                                                                Inbound
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                                         alt="dotted-line"/>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                </div>
                                :
                                ""
                        }
                    </div>

                    <div style={{
                        width: "35%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                Forensic Data Analysis
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_netflow}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>
                        {
                            "overview" in this.state.nf_data ?
                                <div className="scroll-bar-div" style={{
                                    borderRadius: 8,
                                    paddingRight: 8,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "32px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: "100%",
                                    height: 994
                                }}
                                >
                                    {this.nf_sort(this.state.nf_data["overview"]).map((key, i) =>
                                            key in this.state.nf_selected && this.state.nf_selected[key] ?
                                            <Collapsible
                                                trigger={(
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 4,
                                                    }}>
                                                        <div style={{
                                                            fontSize: 12,
                                                            fontFamily: "Inter",
                                                            display: "flex",
                                                            gap: 4,
                                                            color: "#A1A1AA"
                                                        }}>
                                                            <img src="/main/netflow-date.svg" alt="date"/>
                                                            {get_time_string_ms(this.state.nf_data["overview"][key]["first_packet"])}
                                                            <img src="/main/fda-date-arrow-i.svg" alt="arrow"/>
                                                            {get_time_string_ms(this.state.nf_data["overview"][key]["last_packet"])}
                                                            <button
                                                                hidden={this.nf_is_ioa(key)}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    position: "relative",
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    marginLeft: "auto",
                                                                    marginRight: "4px"
                                                                }}
                                                                id="uncheck-ip"
                                                                onClick={() => {
                                                                    let nf_selected = this.state.nf_selected
                                                                    nf_selected[key] = false
                                                                    this.setState({nf_selected: nf_selected})
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src="/main/zinc-close-i.svg"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "190px",
                                                            borderTopRightRadius: 8,
                                                            borderTopLeftRadius: 8,
                                                            borderBottomRightRadius: this.state.nf_collapsibles_state[key] ? 0 : 8,
                                                            borderBottomLeftRadius: this.state.nf_collapsibles_state[key] ? 0 : 8,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            paddingTop: 16,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            background: "#3F3F46",
                                                            gap: 16,
                                                        }}
                                                        >
                                                        <div style={{
                                                            width: '100%',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 56,
                                                                paddingRight: 4,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{this.state.nf_detection_data["host"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>{this.state.nf_detection_data["ip"] ? this.state.nf_detection_data["ip"] : this.state.nf_detection_data["src_ip"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>Host
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <img width={32} height={32} src="/main/netflow-in-out.svg"
                                                                 alt="in-out-arrow"/>

                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 56,
                                                                paddingRight: 4,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{this.get_nf_detail_hostname(key, this.state.nf_data["overview"][key])}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>{this.get_nf_detail_ip(key, this.state.nf_data["overview"][key])}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>Target
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img src="/main/netflow-dotted.svg" alt="dotted-line"/>
                                                        <div style={{
                                                            gap: 4,
                                                            height: 70,
                                                            display: 'inline-flex',
                                                            alignItems: "center"
                                                        }}
                                                        >
                                                            <div style={{
                                                                width: 160,
                                                                padding: 16,
                                                                alignItems: 'center',
                                                                color: 'white',
                                                                fontSize: 22,
                                                                fontFamily: 'Inter',
                                                                display: "flex",
                                                            }}
                                                            >
                                                                Total Traffic
                                                            </div>

                                                            <img width={32} height={32} src="/main/netflow-out.svg"
                                                                 alt="net-out"/>

                                                            <div style={{
                                                                flexDirection: 'column',
                                                                display: 'inline-flex',
                                                                width: 150
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600'
                                                                }}>{this.get_pretty_usage(this.state.nf_data["overview"][key]["total"]["d_out"])}
                                                                </div>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400'
                                                                }}>Packets: {this.state.nf_data["overview"][key]["total"]["p_out"]}
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400'
                                                                }}>
                                                                    Outbound
                                                                </div>
                                                            </div>

                                                            <img width={32} height={32} src="/main/netflow-in.svg"
                                                                 alt="net-in"/>

                                                            <div style={{
                                                                flexDirection: 'column',
                                                                display: 'inline-flex',
                                                                width: 150
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600'
                                                                }}>{this.get_pretty_usage(this.state.nf_data["overview"][key]["total"]["d_in"])}
                                                                </div>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400'
                                                                }}>Packets: {this.state.nf_data["overview"][key]["total"]["p_in"]}
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400'
                                                                }}>
                                                                    Inbound
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                marginLeft: "auto",
                                                                marginRight: "4px",
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>
                                                            <div style={{
                                                                height: "100%",
                                                                width: "32px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                borderRadius: "8px",
                                                                background: "#27272A"
                                                            }}
                                                            >
                                                                <img src="/main/fda-arrow-i.svg" alt="fda-arrow"
                                                                     style={{
                                                                         transform: this.state.nf_collapsibles_state[key] ? '' : 'rotate(180deg)',
                                                                         transition: 'transform 150ms ease',
                                                                     }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )}
                                                open={false}
                                                onOpening={() => {
                                                    let val = this.state.nf_collapsibles_state
                                                    val[key] = true;
                                                    this.setState({nf_collapsibles_state: val})
                                                }}
                                                onClose={() => {
                                                    let val = this.state.nf_collapsibles_state;
                                                    val[key] = false;
                                                    this.setState({nf_collapsibles_state: val})
                                                }}
                                            >
                                                <div className="scroll-bar-div" style={{
                                                    borderBottomLeftRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    padding: 8,
                                                    paddingLeft: 10,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "8px",
                                                    background: "#27272A",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "100%",
                                                    height: 400
                                                }}
                                                >
                                                    {this.get_nf_port_details(this.state.nf_data["overview"][key]["port_data"]).map((item, i) =>
                                                        <div>
                                                            <div style={{
                                                                gap: 4,
                                                                height: 70,
                                                                display: 'inline-flex',
                                                                alignItems: "center"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    display: 'inline-flex',
                                                                    marginLeft: 8,
                                                                    width: 150
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{item["port"] === 0 ? "-" : item["port"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>{item["proto"].toUpperCase()} {item["out"] ? "Outbound" : "Inbound"}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>
                                                                        {get_time_string_ms(item["time"])}
                                                                    </div>
                                                                </div>

                                                                <img width={32} height={32} src="/main/netflow-out.svg"
                                                                     alt="net-out"
                                                                     style={{
                                                                         visibility: item["p_out"] === 0 ? "hidden" : "visible"
                                                                     }}
                                                                />

                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    display: 'inline-flex',
                                                                    width: 150,
                                                                    visibility: item["p_out"] === 0 ? "hidden" : "visible"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{this.get_pretty_usage(item["d_out"])}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>Packets: {item["p_out"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>
                                                                        Outbound
                                                                    </div>
                                                                </div>

                                                                <img width={32} height={32} src="/main/netflow-in.svg"
                                                                     alt="net-in"
                                                                     style={{
                                                                         visibility: item["p_in"] === 0 ? "hidden" : "visible"
                                                                     }}
                                                                />

                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    display: 'inline-flex',
                                                                    width: 150,
                                                                    visibility: item["p_in"] === 0 ? "hidden" : "visible"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{this.get_pretty_usage(item["d_in"])}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>Packets: {item["p_in"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400'
                                                                    }}>
                                                                        Inbound
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src="/main/netflow-dotted.svg" width={"100%"}
                                                                 alt="dotted-line"/>
                                                        </div>
                                                    )}


                                                </div>
                                            </Collapsible>
                                            :
                                            ""


                                    )
                                    }

                                </div>
                                :
                                ""
                        }
                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showHUDAdmin}
                    contentLabel="Supply Sentry"
                    style={{
                        overlay: {
                            zIndex: 1500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "606px",
                            height: "700px",
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                HUD Accounts
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_HUD_Admin}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div className="cp-scroll-bar-div" style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0px 8px 16px 8px",
                            gap: "8px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: "650px"
                        }}
                        >
                            {
                                this.state.HUD_Admin_data.length > 0 ?
                                    this.state.HUD_Admin_data.map((item, i) =>
                                        <div style={{
                                            width: "100%",
                                            marginTop: 8,
                                            flexDirection: 'column',
                                            gap: 8,
                                            display: 'flex'
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                paddingLeft: 4,
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <div style={{
                                                    minHeight: 40,
                                                    paddingRight: 4,
                                                    width: 340,
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {item["username"]}
                                                        </div>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {item["email"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    height: 32,
                                                    paddingRight: 4,
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex',
                                                    marginLeft: "auto"
                                                }}
                                                >
                                                    {
                                                        this.state.hud_delete_confirm.includes(item["email"]) ?
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    position: "relative",
                                                                    width: "24px",
                                                                    height: "24px"
                                                                }}
                                                                id="pre-delete"
                                                                onClick={() => {
                                                                    this.contact_hud_admin(item["oid"], true)
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src="/main/ss-remove-i.svg"
                                                                />
                                                            </button>
                                                            :
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    position: "relative",
                                                                    width: "24px",
                                                                    height: "24px"
                                                                }}
                                                                id="delete-entry"
                                                                onClick={() => {
                                                                    let hud_delete_confirm = this.state.hud_delete_confirm
                                                                    hud_delete_confirm.push(item["email"])
                                                                    this.setState({hud_delete_confirm: hud_delete_confirm})
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src="/main/ss-pre-delete-i.svg"
                                                                />
                                                            </button>

                                                    }

                                                </div>
                                            </div>
                                            <div style={{alignSelf: 'stretch', height: 1, background: '#52525B'}}/>
                                        </div>
                                    )
                                    :
                                    ""
                            }
                        </div>
                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showSupplySentry}
                    contentLabel="Supply Sentry"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1106px",
                            height: "700px",
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "472px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                Client-Specified Supplier Domains
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px"
                        }}
                        >
                            <div className="inner-tip-box"
                                 style={{
                                     height: "100px",
                                     paddingLeft: "16px",
                                     justifyContent: "center",
                                     alignItems: "flex-start"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 16
                                }}
                                     id="number-suppliers">
                                    <div style={{
                                        color: 'white',
                                        fontSize: 48,
                                        width: "85px",
                                        textAlign: "center",
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                    }}>
                                        {this.state.ss_data.length}
                                    </div>
                                    <div style={{
                                        color: '#A1DAF8',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        letterSpacing: 0.03,
                                        width: 325,
                                        wordWrap: 'break-word'
                                    }}>
                                        Supplier(s) Proactively Monitored by Supply Chain Sentry
                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "252px",
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",

                                    fontWeight: "600",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="service-icon"
                                        src="/main/ss-add-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>
                                        Add a new Supplier for Monitoring
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    paddingLeft: "16px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data"
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        gap: "16px",
                                        width: "150px"
                                    }} id="section1">
                                        <div style={{display: "flex", height: "36px", alignItems: "center"}}>Supplier
                                        </div>
                                        <div style={{display: "flex", height: "36px", alignItems: "center"}}>Supplier
                                            Domain
                                        </div>
                                        <div style={{display: "flex", height: "36px", alignItems: "center"}}>Country
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px"
                                    }} id="section2"
                                    >
                                        <input className="transparent-input" type='text' name='supplier'
                                               style={{
                                                   backgroundColor: "#18181B",
                                                   paddingRight: 16,
                                                   paddingLeft: 16,
                                                   borderRadius: 8,
                                                   height: "36px"
                                               }}
                                               onChange={(data) => {
                                                   let ss_entry = this.state.ss_entry
                                                   ss_entry["supplier"] = data.target.value
                                                   this.setState({ss_entry: ss_entry})
                                               }}
                                        />
                                        <input className="transparent-input" type='text' name='supplier'
                                               style={{
                                                   backgroundColor: "#18181B",
                                                   paddingRight: 16,
                                                   paddingLeft: 16,
                                                   borderRadius: 8,
                                                   height: "36px"
                                               }}
                                               onChange={(data) => {
                                                   let ss_entry = this.state.ss_entry
                                                   ss_entry["domain"] = extract_domain(data.target.value)
                                                   this.setState({ss_entry: ss_entry})
                                               }}
                                        />
                                        <div style={{
                                            width: "280px",
                                            height: "36px"
                                        }}>
                                            <Select
                                                maxMenuHeight={200}
                                                styles={colourStyles}
                                                options={countryOptions}
                                                onChange={(data) => {
                                                    let ss_entry = this.state.ss_entry
                                                    ss_entry["country"] = data["value"]
                                                    this.setState({ss_entry: ss_entry})
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button onClick={() => {
                                    if (this.ss_active()) {
                                        this.contact_supply_sentry(this.state.ss_entry)
                                    }
                                }}
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: "auto",
                                            marginTop: "auto",
                                            marginBottom: "8px",
                                            borderRadius: "2px",
                                            background: "transparent",
                                            border: "solid 1px " + (this.ss_active() ? "#06B6D4" : "#737373"),
                                            color: this.ss_active() ? "#06B6D4" : "#737373",
                                            marginRight: "8px"
                                        }}
                                >
                                    <ISubmit style={{
                                        color: this.ss_active() ? "#06B6D4" : "#737373",
                                    }}/>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        width: "572px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                Monitored Suppliers
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_supply_sentry}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div className="cp-scroll-bar-div" style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0px 8px 16px 8px",
                            gap: "8px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: "650px"
                        }}
                        >
                            {
                                this.state.ss_data.length > 0 ?
                                    this.state.ss_data.map((item, i) =>
                                        <div style={{
                                            width: "100%",
                                            marginTop: 8,
                                            flexDirection: 'column',
                                            gap: 8,
                                            display: 'flex'
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                paddingLeft: 4,
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <CircleFlag height={24} countryCode={getISO(item["country"], true)}/>
                                                <div style={{
                                                    minHeight: 40,
                                                    paddingRight: 4,
                                                    width: 340,
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {item["domain"]}
                                                        </div>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {item["supplier"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    height: 32,
                                                    paddingRight: 4,
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        width: 135,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word',
                                                    }}>
                                                        {get_time_string(item["date"])}
                                                    </div>

                                                    {
                                                        this.state.ss_delete_confirm.includes(item["oid"]) ?
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    position: "relative",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    marginLeft: "auto"
                                                                }}
                                                                id="pre-delete"
                                                                onClick={() => {
                                                                    this.contact_supply_sentry(item["oid"], true)
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src="/main/ss-remove-i.svg"
                                                                />
                                                            </button>
                                                            :
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    position: "relative",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    marginLeft: "auto"
                                                                }}
                                                                id="delete-entry"
                                                                onClick={() => {
                                                                    let ss_delete_confirm = this.state.ss_delete_confirm
                                                                    ss_delete_confirm.push(item["oid"])
                                                                    this.setState({ss_delete_confirm: ss_delete_confirm})
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src="/main/ss-pre-delete-i.svg"
                                                                />
                                                            </button>

                                                    }

                                                </div>
                                            </div>
                                            <div style={{alignSelf: 'stretch', height: 1, background: '#52525B'}}/>
                                        </div>
                                    )
                                    :
                                    ""
                            }
                        </div>
                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showBDV}
                    contentLabel="Supply Sentry"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "644px",
                            height: "800px",
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                ShadowVault
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_bdv}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px"
                        }}
                        >
                            <div className="inner-tip-box"
                                 style={{
                                     height: "150px",
                                     paddingLeft: "16px",
                                     justifyContent: "center",
                                     alignItems: "flex-start",
                                     gap: 8,
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 16
                                }}
                                     id="number-bdv">
                                    <div style={{
                                        color: 'white',
                                        fontSize: 48,
                                        width: "135px",
                                        textAlign: "center",
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                    }}>
                                        {this.state.bdv_data.length}
                                    </div>
                                    <div style={{
                                        color: '#A1DAF8',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        letterSpacing: 0.03,
                                        width: 365,
                                        wordWrap: 'break-word'
                                    }}>
                                        Domains are Being Proactively Monitored by ShadowVault
                                    </div>
                                </div>

                                <input className="transparent-input" type='text' name='bdv-search'
                                       style={{
                                           border: "2px solid #A1A1AA",
                                           borderRadius: 8,
                                           paddingRight: 16,
                                           paddingLeft: 16,
                                           height: 40
                                       }}
                                       placeholder="Search Domain"
                                       onChange={(data) => {
                                           this.bdv_filter(data.target.value)
                                       }}
                                />
                            </div>

                            <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "550px"
                            }}
                            >
                                {this.state.bdv_data.map((item, i) =>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                    }}>
                                        <div style={{
                                            paddingLeft: 4,
                                            paddingRight: 8,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 16,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <img src="/main/bdv-i.svg" alt="bdv-icon"/>
                                            <div style={{
                                                flex: '1 1 0',
                                                height: 68,
                                                paddingRight: 4,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {item["type"]}
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {item["domain"]}
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        paddingTop: 4,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}
                                                    >
                                                        {item["tags"].map((tag, i) =>
                                                            <div style={{
                                                                flexDirection: 'column',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                gap: 4,
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                    gap: 8,
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        paddingLeft: 8,
                                                                        paddingRight: 8,
                                                                        background: 'rgba(161, 161, 170, 0.25)',
                                                                        borderRadius: 16,
                                                                        overflow: 'hidden',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            color: '#A1A1AA',
                                                                            fontSize: 14,
                                                                            fontFamily: 'Inter',
                                                                            fontWeight: '600',
                                                                            letterSpacing: 0.07,
                                                                            wordWrap: 'break-word'
                                                                        }}>{tag}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{width: '100%', height: 1, background: '#52525B'}}/>
                                    </div>
                                )
                                }

                            </div>

                        </div>
                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showSDW}
                    contentLabel="ShadowWall"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "644px",
                            height: "800px",
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",

                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                ShadowWall IP Block List
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={this.close_shadow_wall}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px"
                        }}
                        >
                            <div className="inner-tip-box"
                                 style={{
                                     height: "150px",
                                     paddingLeft: "16px",
                                     justifyContent: "center",
                                     alignItems: "flex-start",
                                     gap: 8,
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 16
                                }}
                                     id="number-bdv">
                                    <div style={{
                                        color: 'white',
                                        fontSize: 48,
                                        width: "135px",
                                        textAlign: "center",
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                    }}>
                                        {this.state.shadow_wall_data.length}
                                    </div>
                                    <div style={{
                                        color: '#A1DAF8',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        letterSpacing: 0.03,
                                        width: 365,
                                        wordWrap: 'break-word'
                                    }}>
                                        Malicious IoAs are included in the ShadowFire block list available via API
                                    </div>
                                </div>

                                <input className="transparent-input" type='text' name='bdv-search'
                                       style={{
                                           border: "2px solid #A1A1AA",
                                           borderRadius: 8,
                                           paddingRight: 16,
                                           paddingLeft: 16,
                                           height: 40
                                       }}
                                       placeholder="Search"
                                       onChange={(data) => {
                                           this.sdw_filter(data.target.value)
                                       }}
                                />
                            </div>

                            <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "550px"
                            }}
                            >
                                {this.state.shadow_wall_data.map((item, i) =>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                    }}
                                    >
                                        <div style={{
                                            paddingLeft: 4,
                                            paddingRight: 8,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 16,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <button
                                                onClick={() => {
                                                    this.open_detection(item)
                                                    this.close_shadow_wall()
                                                }}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    border: "none",
                                                }}
                                            >
                                                <div style={{
                                                    padding: 8,
                                                    background: '#27272A',
                                                    borderRadius: 2,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                    <img style={{width: 24, height: 24}}
                                                         src="/main/show-i.svg"
                                                         alt="show"/>
                                                </div>
                                            </button>

                                            <div style={{
                                                flex: '1 1 0',
                                                height: 68,
                                                paddingRight: 4,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {item["ioa"]}
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {item["name"]}
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        paddingTop: 4,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}
                                                    >

                                                        <div style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            gap: 4,
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                gap: 8,
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    background: 'rgba(161, 161, 170, 0.25)',
                                                                    borderRadius: 16,
                                                                    overflow: 'hidden',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 14,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        letterSpacing: 0.07,
                                                                        wordWrap: 'break-word'
                                                                    }}>{item["group"]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                marginLeft: "auto",
                                                justifyContent: "center",
                                                height: 68,
                                                gap: 8
                                            }}>
                                                <div style={{
                                                    fontSize: 12,
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Added: {get_time_string(item["created"])}
                                                </div>
                                                <div style={{
                                                    fontSize: 12,
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Origin: {item["host"]}
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{width: '100%', height: 1, background: '#52525B'}}/>
                                    </div>
                                )
                                }

                            </div>

                        </div>
                    </div>

                </ReactModal>

                <div
                    style={{
                        alignSelf: "stretch",
                        position: "relative",
                        backgroundColor: "#18181b",
                        borderBottom: "1px solid #4f4f4f",
                        alignContent: "flex-start",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "40px",
                    }}
                    id="header"
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginLeft: "-50px",
                            marginRight: "-30px"
                        }}
                    >
                        <img
                            style={{
                                transform: "scale(0.55)",
                            }}
                            alt=""
                            src="/main/CyberShadows-Logo.svg"
                            width={282} height={66}
                        />
                    </div>


                    <Dropdown className="Dropdown-defaults">
                        <Dropdown.Toggle className="Dropdown-defaults">
                        {this.state.company}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="Dropdown-menu">
                            {this.getCompanies()}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div style={{
                        gap: "8px",
                        position: "absolute",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1",
                        width: "100%"
                    }}>
                        {
                            getMenuIcon(this.state.nav_text)
                        }
                        <div
                            style={{
                                position: "relative",
                                fontSize: "17px",
                                letterSpacing: "0.01em",


                                color: "#F9B233",
                                textAlign: "left",
                            }}
                        >
                            {this.state.nav_text === "ShadowHUD" ?
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px"
                                }}>
                                    ShadowHUD
                                    <div style={{
                                        color: "#65A30D"
                                    }}>
                                        (Intelligence Led Cyber Operations)
                                    </div>
                                </div>
                                :
                                this.state.nav_text}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        alignSelf: "stretch",
                        flex: "1",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        paddingTop: "40px",
                        gap: "8px",
                        textAlign: "center",
                        fontSize: "18px",
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                        zIndex: "10"
                    }}
                    id="inner-main"
                >

                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            position: "relative",
                            gap: "10px",
                            width: this.state.side_collapsed === 2 ? "68px" : "180px",
                            zIndex: "10",
                            backgroundColor: "#18181b",
                            borderRight: "1px solid #52525b",
                            boxSizing: "border-box",
                            transition: 'width 1s ease',
                        }}
                        id="side-bar"
                    >
                        <div
                            style={{
                                margin: "0",
                                position: "absolute",
                                left: "0px",
                                width: "calc(100% - 8px)",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                zIndex: "3",
                            }}
                            id="Menu"
                        >
                            <button
                                className="menu-entry"
                                onClick={this.collapse_btn}
                                style={{
                                    width: "68px"
                                }}
                                id="collapse-btn"
                            >
                                <div
                                    style={{
                                        backgroundColor: "rgba(19, 19, 19, 0.05)",
                                        minWidth: "180px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 16px",
                                        boxSizing: "border-box",
                                        zIndex: "0",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                                transform: this.state.side_collapsed === 2 ? 'rotate(180deg)' : '',
                                                transition: 'transform 150ms ease',
                                            }}
                                            alt=""
                                            src="/main/arrows.svg"
                                        />
                                    </div>
                                </div>
                            </button>
                            <div
                                className="side-menu"
                            >
                                {this.make_menu_btn("ShadowHUD", 0, 0, false)}

                                {
                                    this.side_bar.map((item, i) =>
                                        <Collapsible
                                            trigger={this.make_trigger_div(Object.keys(item)[0], this.state.entryOpen[i])}
                                            open={i === 0}
                                            onOpen={() => {
                                                let val = this.state.entryOpen;
                                                val[i] = true;
                                                this.setState({entryOpen: val})
                                            }}
                                            onClose={() => {
                                                let val = this.state.entryOpen;
                                                val[i] = false;
                                                this.setState({entryOpen: val})
                                            }}
                                        >
                                            {
                                                Object.entries(item[Object.keys(item)[0]]).map(([key, value]) =>
                                                    this.make_menu_btn(key, value)
                                                )
                                            }
                                        </Collapsible>
                                    )
                                }
                            </div>

                            <div style={{minHeight: "20px", minWidth: "20px"}}/>

                            <button
                                className="menu-entry"
                                onClick={() => {
                                    this.setState({showRequestIntel: true})
                                }}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                }}
                                id={"request-intel"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#7F1D1D",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt=""
                                            src="/main/fire.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                Request for Intel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#EF4444",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>

                            <button
                                className="menu-entry"
                                hidden={true}
                                onClick={() => {

                                }}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                                id={"reporting-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#78350F",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt=""
                                            src="/main/reporting.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                Reporting
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#F59E0B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>

                            <button
                                className="menu-entry"
                                onClick={this.open_client_playbook}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                                id={"playbook-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#164E63",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="client-playbook"
                                            src="/main/client-playbook.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                Client Playbook
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#06B6D4",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>

                            <button
                                className="menu-entry"
                                onClick={this.open_supply_sentry}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                                id={"sentry-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#312E81",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="supply-sentry"
                                            src="/main/supply-sentry.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                Supply Chain Sentry
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#6366F1",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>


                            <button
                                className="menu-entry"
                                onClick={this.open_bdv}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                                id={"bdv-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#701A75",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-vault"
                                            src="/main/bdv-i.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                ShadowVault
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#D946EF",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>


                            <button
                                className="menu-entry"
                                onClick={this.open_shadow_wall}
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                                id={"sdw-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#713F12",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-wall"
                                            src="/main/sdw-i.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                ShadowWall
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#F59E0B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </button>

                            <a
                                hidden={this.props.user().company !== "KryptoKloud Ltd"}
                                className="menu-entry"
                                // href={ReportPDF} rel='noreferrer' target='_blank'
                                style={{
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    textDecoration: "none",
                                    marginTop: "4px",
                                }}
                                onClick={() => {
                                    this.setState({showReporting: true})
                                }}
                                id={"ai-rep-btn"}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#064E3B",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >

                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-wall"
                                            src="/main/ai-rep-i.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",


                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                            }}
                                        >
                                            <div style={{width: "150px"}}>
                                                AI Reporting
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#10B981",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </a>

                            <div
                                className="menu-entry"
                                style={{
                                    cursor: "revert",
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "auto",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#27272A",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-agent"
                                            src="/main/ShadowAgent.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",
                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '700'
                                            }}>Shadow</span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400'
                                            }}>Agent</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#52525B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </div>

                            <div
                                className="menu-entry"
                                style={{
                                    cursor: "revert",
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#27272A",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-fire"
                                            src="/main/ShadowFire.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",
                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '700'
                                            }}>Shadow</span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400'
                                            }}>Fire</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#52525B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </div>

                            <div
                                className="menu-entry"
                                style={{
                                    cursor: "revert",
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#27272A",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            alt="shadow-lens"
                                            src="/main/ShadowLens.svg"
                                        />
                                        <div
                                            style={{
                                                position: "relative",
                                                fontSize: "14px",
                                                letterSpacing: "0.01em",
                                                color: "white",
                                                textAlign: "left",
                                                overflow: "hidden",
                                                width: this.state.side_collapsed === 2 ? "0px" : "150px",
                                                transition: 'width 1s ease',
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '700'
                                            }}>Shadow</span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400'
                                            }}>Lens</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#52525B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </div>


                            <div
                                className="menu-entry"
                                style={{
                                    cursor: "revert",
                                    width: this.state.side_collapsed === 2 ? "68px" : "180px",
                                    transition: 'width 1s ease',
                                    marginTop: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: "#27272A",
                                        width: "178px",
                                        height: "40px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px 8px 16px",
                                        boxSizing: "border-box",
                                        overflow: "hidden",
                                        zIndex: "0",
                                        transition: 'width 1s ease',
                                    }}
                                    onClick={this.sign_out}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            paddingRight: 8,
                                            gap: "8px",
                                        }}
                                    >
                                        <img
                                            style={{position: "relative", width: "20px", height: "20px"}}
                                            alt=""
                                            src="/main/people--user2.svg"
                                        />
                                        <div hidden={this.state.side_collapsed === 2}
                                             style={{position: "relative", fontWeight: "600", fontSize: "14px"}}>
                                            {this.state.username}
                                        </div>

                                        <img
                                            style={{width: "20px", height: "20px", marginLeft: "auto"}}
                                            alt="sign-out"
                                            src="/main/sign-out-i.svg"
                                        />

                                    </div>
                                </div>
                                <div
                                    style={{
                                        left: "0px",
                                        backgroundColor: "#52525B",
                                        width: "2px",
                                        height: "40px"
                                    }}
                                />
                            </div>

                        </div>

                    </div>


                    {this.renderSwitch()}
                </div>
                <div
                    style={{
                        position: "relative",
                        borderRadius: "2px",
                        backgroundColor: "#171717",
                        width: "310px",
                        height: "913px",
                        display: "none",
                    }}
                />
            </div>
        )
    }
}