import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/login.component'
import SignUp from './components/signup.component'
import MainComponent from './components/main.component'
import Ice from './components/ice.component'
import Interest from "./components/interest.component";

class data {
    static user = {
        company: '',
        username: ''
    }
}
function user(val) {
    if (val === true) {
        data.user = {
            company: '',
            username: ''
        }
    } else if (val) {
        data.user = val
    }
    return data.user
}


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login user={user}/>} />
                <Route path="/dashboard" element={<MainComponent user={user}/>} />
                <Route path="/sign-in" element={<Login user={user}/>} />
                <Route path="/ice" element={<Ice user={user}/>} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/interest" element={<Interest />} />
            </Routes>
        </Router>
    )
}
export default App